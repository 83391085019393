import React, { useEffect, useRef, useState } from "react";
import "../../scss/Users.scss";
import { getStripe } from "../../util/stripe";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/userSlice";
import axios from "../../util/api";
import toast from "react-hot-toast";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";

const userAddPlanList = [0, 3, 5, 10, 20];

function Users() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userList, setUserList] = useState([]);
  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
  });
  const dispatch = useDispatch();
  const [totalCouponApplied, setTotalCouponApplied] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const user = useSelector((state) => state.user.user);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (user) => {
    const data = {
      user_id: user.userId,
    };
    dispatch(setLoading(true));
    axios
      .post("/delete_user_subscribed", data)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.success) {
          toast.success(res.message);
          getUserList();
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error("Something went wrong");
        }
        dispatch(setLoading(false));
      });
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "33%",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: "33%",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "userEmail",
      width: "33%",
      ...getColumnSearchProps("userEmail"),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record) => (
        <Button
          type="primary"
          onClick={() => handleDelete(record)} // Call the delete function with the user's data
        >
          Delete
        </Button>
      ),
    },
  ];

  const getUserList = () => {
    dispatch(setLoading(true));
    axios
      .get(`/get_user_list_by_admin/${user.user_id}`)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.success) {
          if (res.users && res.users.length > 0) {
            const transformedUser = res.users.map((user, index) => ({
              key: index,
              firstName: user.first_name,
              lastName: user.last_name,
              userEmail: user.email,
              userId: user.user_id,
            }));
            setUserList(transformedUser);
          } else {
            setUserList([]);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error("Something went wrong");
        }
        dispatch(setLoading(false));
      });
  };

  const getUserCouponsList = () => {
    dispatch(setLoading(true));
    axios
      .get(`/get_promo_codes/${user.user_id}`)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.success) {
          if (res.promo_codes && res.promo_codes.length > 0) {
            setTotalCouponApplied(res.promo_codes.length - 1);
          } else {
            setTotalCouponApplied(0);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error("Something went wrong");
        }
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (user && user.user_id) {
      getUserList();
      getUserCouponsList();
    }
  }, [user]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendInviteToUser = (subscriptionId) => {
    const data = {
      username: `${firstName} ${lastName}`,
      email: userEmail,
      admin_id: user.user_id,
      admin_name: `${user.first_name} ${user.last_name}`,
      subscription_id: subscriptionId 
    };
    dispatch(setLoading(true));
    axios
      .post("/add_new_user_subscribed", data)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.success) {
          const newUser = {
            firstName,
            lastName,
            userEmail,
            userId: res?.user_id,
            key: userList.length + 1,
          };
          setUserList([...userList, newUser]);
          setFirstName("");
          setLastName("");
          setUserEmail("");
          setFormErrors({
            firstName: "",
            lastName: "",
            userEmail: "",
          });

          toast.success(res.message);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error("Something went wrong");
        }
        dispatch(setLoading(false));
      });
  };

  const createStripeUser = async () => {
    try {
      const stripe = getStripe();

      const customer = await stripe.customers.create({
        name: `${firstName} ${lastName}`, // Customer's name
        email: userEmail, // Customer's email
        metadata: {
          // Optional metadata (key-value pairs)
          userId: uuidv4(), // Your internal user ID for reference
          notes: "Invited User",
        },
      });
      if (customer && customer.id) {
        const twoYearsFromNow =
          Math.floor(Date.now() / 1000) + 730 * 24 * 60 * 60;
         stripe.subscriptions.create({
          customer: customer.id,
          items: [
            {
              price: "price_1QA9ZHC1l4uJrR3EXLujF7XZ",
              // price: "price_1Q8mZcC1l4uJrR3EVDJ3fKGz", // product Id added here development env
            },
          ],

          trial_end: twoYearsFromNow,
          expand: ["latest_invoice.payment_intent"],
        }).then((subscription) => {
          sendInviteToUser(subscription.id);
        }).catch((error) => {
          console.error("Error creating subscription:", error);
        });
      }
    } catch (error) {
      console.error("Error fetching customer:", error);
    }
  };

  const handleAddData = () => {
    if (totalCouponApplied === 0 || userList.length === userAddPlanList[totalCouponApplied] ) {
      toast.error("Please upgrade the plan.")
      return;
    } 
    
    let errors = {};

    if (!firstName.trim()) {
      errors.firstName = "First name is required.";
    }

    if (!lastName.trim()) {
      errors.lastName = "Last name is required.";
    }

    if (!userEmail.trim()) {
      errors.userEmail = "Email is required.";
    } else if (!validateEmail(userEmail)) {
      errors.userEmail = "Invalid email format.";
    }

    if (Object.keys(errors).length === 0) {
      createStripeUser();
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="users-wrapper">
      <h3 className="page-heading">Invite Users</h3>
      <div className="user-detail">
        <div className="name-field">
          <div className="form-fields">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {formErrors.firstName && (
              <p className="form-errors">{formErrors.firstName}</p>
            )}
          </div>
          <div className="form-fields">
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {formErrors.lastName && (
              <p className="form-errors">{formErrors.lastName}</p>
            )}
          </div>
        </div>
        <div>
          <input
            type="email"
            placeholder="Email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          {formErrors.userEmail && (
            <p className="form-errors">{formErrors.userEmail}</p>
          )}
        </div>
        <button type="primary" onClick={handleAddData}>+ Add</button>
      </div>
      <div className="table-container">
        <Table
          columns={columns}
          dataSource={userList}
          locale={{ emptyText: "No Users Added" }}
        />
      </div>
    </div>
  );
}

export default Users;
