import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import companyLogo from '../../assests/images/headerLogo.png';
import '../../scss/Navbar.scss';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { setUser } from '../../redux/userSlice';
import profileDefaultImage from '../../assests/images/sarah.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const [isMobile, setIsMobile] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem('user');
    dispatch(setUser({}));
    navigate('/login');
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary header-wrapper">
        {isMobile && (
          <div className='navbar-brand nav-logo' onClick={() => navigate('/')}>
            <img className='logo' src={companyLogo} alt='logo'></img>
            <span>MIND MATCH AI</span>
          </div>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='me-auto'>
            {!isMobile && (
              <div className='navbar-brand nav-logo' onClick={() => navigate('/')}>
                <img className='logo' src={companyLogo} alt='logo'></img>
                <span>MIND MATCH AI</span>
              </div>
            )}
            <div className='nav-items'>
              <Link to='/' state={{ ClickedOn: "aboutUs" }}>About Us</Link>
              <Link to='/' state={{ ClickedOn: "pricing" }}>Pricing</Link>
              <NavLink to='/blogs'>Blogs</NavLink>
            </div>
            {!user.email ? (<div className='button-section'>
              <button className='btn contact-sales' onClick={() => navigate('/signup')}>Try for free</button>
              <button className='btn sign-in' onClick={() => navigate('/login')}>Sign In</button>
            </div>) : (
              <div className='user-profile-section'>
                <NavDropdown id="basic-nav-dropdown" title={`${user.first_name} ${user.last_name}`}>
                  <NavDropdown.Item onClick={() => navigate('/profile')}>Profile</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
                {!isMobile && (<img src={user?.profileUrl ? `data:image/png;base64,${user?.profileUrl}` : profileDefaultImage} alt='img'></img>)}
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default NavBar;