import React from 'react'
import '../../scss/Auth.scss';
import rightImage from '../../assests/images/Screen_1.png';
import industry1 from '../../assests/images/industry1.png';
import industry2 from '../../assests/images/industry2.png';
import industry3 from '../../assests/images/industry3.png';
import industry4 from '../../assests/images/industry4.png';
import companyLogo from '../../assests/images/headerLogo.png'
import SocialLogin from '../../components/SocialLogin';
import { Link, useNavigate } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

export default function Auth({ page }) {
    const navigate = useNavigate();
    return (
        <>
            <div className='auth-page'>
                <div className="auth-container">
                    <div className="auth-left">
                        <div className="auth-left-content">
                            <div className='nav-logo' onClick={() => navigate('/')}>
                                <img className='logo' src={companyLogo} alt='LogoImg'></img>
                                <span>MIND MATCH AI</span>
                            </div>
                            <SocialLogin page={page} />
                            {page === 'login' && <Login />}
                            {page === 'signup' && <Signup />}
                            {page === 'forgotpassword' && <ForgotPassword />}
                            {page === 'resetpassword' && <ResetPassword />}

                            {!(page === 'forgotpassword' || page === 'resetpassword') && <div className='forgot-password'><Link to='/forgotpassword'>Forgot Password?</Link></div>}

                        </div>
                        <div className="auth-left-info">
                            {page === 'login' ? (
                                <h6>Don’t have an account yet? <Link to='/signup'>Sign Up</Link></h6>
                            ) : (
                                <h6>Already have an account? <Link to='/login'>Sign in</Link></h6>
                            )}
                            <p>By signing up, you agree to our <Link to={'/termsOfService'}>Terms of Service</Link>, <Link>Copyright Policy</Link> and acknowledge you’ve read our <Link to={'/privacyPolicy'}>Privacy Policy</Link>.</p>
                        </div>
                    </div>
                    <div className="auth-right">
                        <div className="auth-right-content">
                            <h5 className='heading'>Endless Practice, Targeted Skill Refinement, and Detailed Progress Tracking Await</h5>
                            <img className='right-section-image' src={rightImage} alt="loading" />
                            <div className='industries'>
                                <div className='industry'><img src={industry1} alt="loading" />Industry & Manufacturing</div>
                                <div className='industry'><img src={industry2} alt="loading" />Transportation & Logistics</div>
                            </div>
                            <div className='industries mt-3'>
                                <div className='industry'><img src={industry3} alt="loading" />Banks & Insurance</div>
                                <div className='industry'><img src={industry4} alt="loading" />Non-Profit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
