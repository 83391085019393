import axios from "axios";
import { backend_url } from "./config";

const instance = axios.create({
    baseURL: backend_url,
    headers: {
        "Content-Type": 'multipart/form-data'
    }
});

instance.interceptors.response.use(
    (res) => res.data
)

export default instance;