import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import { Accordion } from 'react-bootstrap';
import axios from '../../util/api';
import { backend_url } from '../../util/config';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';

export default function FeedBackQuestions({ roundData }) {
    const dispatch = useDispatch();
    const [questions, setQuestions] = useState([]);
    const [tabName, settabName] = useState('');

    const handleTabChange = (e, roundName) => {
        e.preventDefault();
        settabName(roundName);
    }

    const getQuestion = () => {
        let session_ids = [];
        roundData.forEach(element => {
            if (element.roundname === tabName) {
                session_ids.push(element.session_id);
            } else if (element.roundname === tabName) {
                session_ids.push(element.session_id);
            } else if (element.roundname === tabName) {
                session_ids.push(element.session_id);
            } else if (element.roundname === tabName) {
                session_ids.push(element.session_id);
            }
        });
        if (session_ids.length > 0) {
            const config = {
                method: 'post',
                url: `${backend_url}/get_session_details`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: { session_ids }
            }
            dispatch(setLoading(true));
            axios.request(config).then(res => {
                dispatch(setLoading(false));
                if (res.session_details) {
                    setQuestions(res.session_details);
                }
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error);
            })
        }
    }

    useEffect(() => {
        if (roundData.length > 0) {
            settabName(roundData[0]?.roundname);
            if(tabName === roundData[0]?.roundname){
                getQuestion();
            }
        }
    }, [roundData]);

    useEffect(() => {
        if (roundData.length > 0) {
            getQuestion();
        }
    }, [tabName])


    return (
        <>
            <div className="feedback-questions-wrapper">
                <Nav defaultActiveKey={'link-1'}>
                    {roundData.map((item, index) => {
                        return <Nav.Item key={index}>
                            <Nav.Link onClick={(e) => handleTabChange(e, item.roundname)} eventKey={`link-${index + 1}`}>{item.roundname}</Nav.Link>
                        </Nav.Item>
                    })}
                </Nav>

                <div className="tab-content">
                    <Accordion>
                        {questions.map((item, index) => {
                            return <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header><div className="question"><div><span className='serial-no'>Q{index + 1}</span></div>{item.question}</div></Accordion.Header>
                                <Accordion.Body>
                                    <div className='content'>
                                        <div className="answer">
                                            <span>Answer</span>
                                            <p>{item.answer}</p>
                                        </div>
                                        <div className="feedback">
                                            <span>Feedback</span>
                                            <p>{item.feedback}</p>
                                        </div>
                                        <div className="suggestion">
                                            <span>Suggested Answer</span>
                                            <p>{item.suggested_answers}</p>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        })}
                    </Accordion>
                </div>
            </div>
        </>
    )
}
