import React, { useState } from 'react'
import companyLogo from '../assests/images/headerLogo.png';
import sarahImage from '../assests/images/sarah.png';
import { LuBarChart2 } from "react-icons/lu";
import { LuLogOut, LuSettings  } from "react-icons/lu";
import { useNavigate, NavLink } from 'react-router-dom';
import { RiArrowDownSLine } from "react-icons/ri";
import { RiArrowUpSLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select"
import { RiCustomerService2Fill } from "react-icons/ri";
import SupportLogo from '../assests/images/supportLogo.png';
import { setLoading, setUser } from '../redux/userSlice';
import axios from '../util/api';
import toast from 'react-hot-toast';

export default function SideBar() {
    const dispatch = useDispatch();
    const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
    const [isInterviewTabActive, setInterviewTabActive] = useState(true);
    const [isAdministratorActive, setAdministratorActive] = useState(true);
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const profile = useSelector(state => state.user.userProfile);
    const expand = 'lg';
    const [modalShow, setModalShow] = useState(false);
    const issuesOption = [
        { value: "Sing In", label: "Sign In" },
        { value: "Profile Setup", label: "Profile Setup" },
        { value: "Document Upload", label: "Document Upload" },
        { value: "Answer Recording", label: "Answer Recording" },
        { value: "Payment", label: "Payment" },
        { value: "Others", label: "Others" },
    ];

    const [formData, setFormData] = useState({
        type: null,
        subject: '',
        description: '',
    })

    const [formErrors, setFormErrors] = useState({
        typeError: '',
        subjectError: '',
        descriptionError: ''
    })

    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }



    const handleReport = (e) => {
        e.preventDefault();
        let isError = false;
        const errorObj = {
            typeError: '',
            subjectError: '',
            descriptionError: ''
        };
        if (formData.type === null) {
            errorObj.typeError = 'Please select an issue type';
            isError = true;
        } else if (formData.subject === '') {
            errorObj.subjectError = 'Please enter a subject';
            isError = true;

        } else if (formData.description === '') {
            errorObj.descriptionError = 'Please enter a description';
            isError = true;
        }
        setFormErrors(errorObj);
        if (!isError) {
            let data = new FormData();
            data.append('type', formData.type);
            data.append('subject', formData.subject);
            data.append('description', formData.description);

            dispatch(setLoading(true));
            axios.post('/send_support_email', data).then((res) => {
                dispatch(setLoading(false));
                if (res.success) {
                    toast.success('Email send Successfully');
                    setModalShow(false);
                }
            }).catch((error) => {
                console.log(error)
                dispatch(setLoading(false));
            })
        }
    }
    const logout = () => {
        localStorage.removeItem('user');
        dispatch(setUser({}));
        navigate('/login');
    }
    return (
        <>
            <Navbar key={expand} expand={expand} className="sidebar-navbar flex-column">
                <Container fluid>
                    {/* <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand> */}
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setOffcanvasOpen(!isOffcanvasOpen)} />
                    <Navbar.Offcanvas
                        show={isOffcanvasOpen}
                        onHide={() => setOffcanvasOpen(false)}
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="start"
                    >
                        <Offcanvas.Header closeButton>
                            {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Offcanvas
                            </Offcanvas.Title> */}
                        </Offcanvas.Header>
                        <Offcanvas.Body className='flex-column'>
                            <div className='nav-logo' onClick={() => navigate('/')}>
                                <img className='logo' src={companyLogo} alt='LogoImg'></img>
                                <span>MIND MATCH AI</span>
                            </div>
                            <div className='menus'>
                                <div className="menu">
                                    <div className='interview-tab'>
                                        <button style={isInterviewTabActive ? { color: "#104CBA" } : {}} onClick={() => setInterviewTabActive(!isInterviewTabActive)}>
                                            <LuBarChart2 />Interview
                                        </button>
                                        {isInterviewTabActive ? <RiArrowUpSLine className='dropdown-arrow' onClick={() => setInterviewTabActive(!isInterviewTabActive)} /> : <RiArrowDownSLine className='dropdown-arrow' onClick={() => setInterviewTabActive(!isInterviewTabActive)} />}
                                    </div>
                                    <div className='dropdown-items' style={!isInterviewTabActive ? { display: "none" } : {}}>
                                        <NavLink to="/profile" onClick={() => setOffcanvasOpen(false)}>Profile</NavLink>
                                        <NavLink to="/interview" onClick={() => setOffcanvasOpen(false)}>Start Interview</NavLink>
                                        <NavLink to="/feedback" onClick={() => setOffcanvasOpen(false)} className='feedback-link'>Feedback <span className='feedback-count'>{profile?.total_interviews || 0}</span></NavLink>
                                    </div>
                                    <div className='interview-tab'>
                                        <button style={isAdministratorActive ? { color: "#104CBA" } : {}} onClick={() => setAdministratorActive(!isAdministratorActive)}>
                                            <LuSettings />Administrator
                                        </button>
                                        {isAdministratorActive ? <RiArrowUpSLine className='dropdown-arrow' onClick={() => setAdministratorActive(!isAdministratorActive)} /> : <RiArrowDownSLine className='dropdown-arrow' onClick={() => setAdministratorActive(!isAdministratorActive)} />}
                                    </div>
                                    <div className='dropdown-items' style={!isAdministratorActive ? { display: "none" } : {}}>
                                        <NavLink to="/subscription" onClick={() => setOffcanvasOpen(false)} className='feedback-link'>Subscriptions</NavLink>
                                        <NavLink to='/applyCoupon'  onClick={() => setOffcanvasOpen(false)}>Apply Coupon</NavLink>
                                        <NavLink to='/users'  onClick={() => setOffcanvasOpen(false)}>Invite Users</NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className='dashboard-help-section'>
                                <div className="support" onClick={() => {
                                    setFormData({
                                        type: null,
                                        subject: '',
                                        description: '',
                                    }); setModalShow(true)
                                }}><img src={SupportLogo} alt='logo'></img>Support</div>
                                <div className="sidebar-profile">
                                    <NavLink to='/profile' onClick={() => setOffcanvasOpen(false)}><img src={user?.profileUrl ? `data:image/png;base64,${user.profileUrl}` : sarahImage} alt="profile" /></NavLink>
                                    <div className='sidebar-profile-content'>
                                        <div className="profile-name">
                                            {user?.first_name} {user?.last_name}
                                            <LuLogOut onClick={logout} />
                                        </div>
                                        <div className="profile-email">
                                            {user?.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <Modal
                className='report_bug_modal'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title><RiCustomerService2Fill /> Support /Report a bug</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleReport}>
                        <div className='issues-options'>
                            <label htmlFor='issue'>I want to raise an issue for<span>*</span></label>
                            <Select
                                defaultValue={formData.type}
                                onChange={(e) => setFormData({ ...formData, type: e.value })}
                                options={issuesOption}
                                placeholder="Select an option"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '10px',
                                        padding: '4px 0'
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        fontSize: '14px'
                                    })
                                }}
                            />
                            <p>{formErrors.typeError}</p>

                        </div>
                        <div className='subject-section'>
                            <label htmlFor='subject'>Subject<span>*</span></label>
                            <input type='text' id='subject' name='subject' onChange={handleFormChange} placeholder='Enter a subject'></input>
                            <p>{formErrors.subjectError}</p>
                        </div>
                        <div className='description-section'>
                            <label htmlFor='description'>Description<span>*</span></label>
                            <textarea type='text' id="description" name='description' onChange={handleFormChange} placeholder='Enter a description'></textarea>
                            <p>{formErrors.descriptionError}</p>
                        </div>
                        <button className='submit-btn'>Submit</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
