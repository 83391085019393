import React, { useState, useEffect } from 'react';
import { SlArrowLeftCircle, SlArrowRightCircle } from 'react-icons/sl';

const Section4 = () => {
  // Array of feedback data
  const feedbackData = [
    {
      id: 1,
      text: 'As someone preparing for job interviews, Mind Match AI interview platform has been an absolute game-changer! The personalized feedback I receive after each mock session has helped me fine-tune my responses and gain confidence. Highly recommended!',
      name: 'Hannah Thompson, Customer Success Manager',
    },
    {
      id: 4,
      text: 'The AI really helps me anticipate the kind of questions interviewers might ask. After each mock session, I got feedback that helped me refine my answers and clarify my thoughts.',
      name: 'Lisa Turner, Product Manager',
    },
    {
      id: 5,
      text: 'Mind Match AI helped me polish my responses to technical questions. The detailed feedback allowed me to focus on the specifics, and now I\'m more confident heading into interviews.',
      name: 'Ethan Walker, Full Stack Developer',
    },
    {
      id: 6,
      text: 'The platform is easy to use, and the feedback is actionable. I love how the AI challenges me with different scenarios, which made me more prepared for the unexpected in real interviews.',
      name: 'Mia Hernandez, HR Coordinator',
    },
    {
      id: 7,
      text: 'Mind Match AI is like having a personal interview coach available anytime. My interview skills improved drastically, and I got hired after just a month of using the platform!',
      name: 'Jake Foster, Operations Analyst',
    },
    {
      id: 8,
      text: 'The variety of interview questions offered by the AI helped me practice across multiple areas. I became more well-rounded in my answers, thanks to the personalized feedback.',
      name: 'Aisha Khan, Digital Marketing Strategist',
    },
    {
      id: 9,
      text: 'After several practice sessions with Mind Match AI, I became more concise and precise in my answers. It was a game-changer for me during my final-round interviews!',
      name: 'Oliver Davis, Cybersecurity Analyst',
    },
    {
      id: 10,
      text: 'The feedback really highlighted some nuances in my communication style that I was unaware of. With the improvements suggested by Mind Match AI, I now articulate my ideas much better.',
      name: 'Rachel Scott, Content Writer',
    },
    {
      id: 11,
      text: 'This platform is an excellent investment in your career. The AI feedback is detailed and accurate, helping me target and fix my weak points before the actual interview.',
      name: 'Alex Johnson, Software Architect',
    },
    {
      id: 12,
      text: 'Mind Match AI helped me develop strong STAR method responses. The personalized guidance made a huge difference in how I approach behavioral questions!',
      name: 'Hannah Thompson, Customer Success Manager',
    },
    {
      id: 13,
      text: 'I was impressed with how quickly I was able to improve my interview skills. The simulations were so realistic, and the feedback I received was always insightful and actionable.',
      name: 'Ben Carter, Data Scientist',
    },
  ];

  // State to manage current feedback index
  const [currentFeedback, setCurrentFeedback] = useState(0);

  // Function to handle next feedback
  const handleNextFeedback = () => {
    setCurrentFeedback((prev) => (prev + 1) % feedbackData.length);
  };

  // Function to handle previous feedback
  const handlePrevFeedback = () => {
    setCurrentFeedback((prev) =>
      prev === 0 ? feedbackData.length - 1 : prev - 1
    );
  };

  // Automatically move feedback every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextFeedback();
    }, 8000); // Change feedback every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentFeedback]);

  return (
    <div className='section-4-wrapper'>
      <div className='section-4-body'>
        <div className='left-section'>
          <div className='heading-section'>
            <span>SUCCESS STORIES</span>
            <h2 className='section-heading'>Customer Success</h2>
          </div>
          <div className='description'>
            <p>{feedbackData[currentFeedback].text}</p>
            <p className='feedback-name'>{feedbackData[currentFeedback].name}</p> {/* Display name */}
            <div className='btn-section'>
              <SlArrowLeftCircle size={40} onClick={handlePrevFeedback} />
              <SlArrowRightCircle size={40} onClick={handleNextFeedback} />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className='right-section'>
          <img src='/section4Img.png' alt='section visual' />
          <div className='mock-interviews'>
            <img src='/Growth.png' alt='growth logo' />
            <p>Scored 98.99 average in mock interviews</p>
          </div>
          <div className='got-hired'>
            <img src='/Suitcase.png' alt='suitcase logo' />
            <p>Got hired in 4 weeks</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
