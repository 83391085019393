import React from 'react';
import section3Logo4 from "../assests/images/section3Logo4.png";
import jobSeeker from "../assests/images/jobSeeker.png";
import careerCoaches from "../assests/images/careerCoaches.png";
import recruiters from "../assests/images/recruiters.png";
import institution from "../assests/images/institution.png";
import training from "../assests/images/training.png";

import skillDevelopementProgram from "../assests/images/skillDevelopmentProgram.png";

const WhoCanUseItSection = () => {

  return (
    <div className='who-can-use-it-wrapper'>
      <div className="who-can-use-it-body">
          <div className="section-heading">
            {/* <span>OUR IMPACT</span> */}
            <h2>Who Can Use It</h2>
          </div>
          <div className="cards-container">
            <div className="card">
              <img src={jobSeeker} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">Job Seekers</span>
                <p className="card-description">
                Practice real-life interview questions tailored to your desired job role, receive instant feedback on your performance, and refine your answers to stand out from the crowd.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={careerCoaches} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">
                  Career Coaches
                </span>
                <p className="card-description">
                  Enhance your coaching practice with data-driven interview and help clients improve interview skills through realistic mock interview simulations.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={recruiters} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">Recruiters</span>
                <p className="card-description">
                Enhance your hiring process by providing candidates with AI-driven mock interviews tailored to your job requirements. Help them practice, improve, and perform better in real interviews, increasing their chances of success.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={skillDevelopementProgram} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">Skill Development Programs</span>
                <p className="card-description">
                Personalized Learning & Interview Practice<br />

                Help learners upskill with AI mock interview tools to prepare them for real-world opportunities.
                </p>
              </div>
            </div>
            <div className="card">
              <img src={institution} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">University Career Services</span>
                <p className="card-description">
                AI-Powered Career Support for Students <br />

                Equip your students with personalized mock interview practice, helping them land the right opportunities after graduation.</p>
              </div>
            </div>
            <div className="card">
              <img src={training} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">Training and Placement Services
                </span>
                <p className="card-description">
                Maximize Placements with AI-Powered Mock Interviews <br /> 
                Prepare candidates for success with mock interview sessions, boosting their confidence and increasing selection rates.</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default WhoCanUseItSection;
