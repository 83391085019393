import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
function PrivateRoutes({ children }) {
    const user = useSelector((state) => state?.user?.user);
    const [loggedIn, setLoggedIn] = useState(user.user_id ? true : false);
    // const [loggedIn, setLoggedIn] = useState(true);
    return <>{loggedIn ? children : <Navigate to='/login' replace='true' />}</>;
}

export default PrivateRoutes;
