import React, { useState, useEffect } from 'react'
import likeIcon from '../../assests/images/like-icon.png'
import FeedBackChart from '../../components/feedback/FeedBackChart';
import '../../scss/Feedback.scss';
import FeedBackQuestions from '../../components/feedback/FeedBackQuestions';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import { backend_url } from '../../util/config';
import axios from '../../util/api';
import Select from "react-select";

function Feedback() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const [totalRound, settotalRound] = useState([]);
  const [roundData, setroundData] = useState([]);
  const [selectedRound, setSelectedRound] = useState(null)
  const [overallFeedback, setoverallFeedback] = useState('');
  const [overallSuggestion, setoverallSuggestion] = useState('');

  const roundOption = totalRound.map((round, index) => {
    return { value: round, label: `Interview ${index + 1}` }
  });

  useEffect(() => {
    const config = {
      method: 'post',
      url: `${backend_url}/last_interview/${user.user_id}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    dispatch(setLoading(true));
    axios.request(config).then((res) => {
      dispatch(setLoading(false));
      if (res.interview_round_name) {
        setroundData(res.interview_round_name);
      }
      if (res.overall_feedback && res.overall_feedback[0]) {
        setoverallFeedback(res.overall_feedback[0])
      }
      if (res.suggestion) {
        setoverallSuggestion(res.suggestion);
      }
    }).catch((error) => {
      dispatch(setLoading(false))
      console.log("error", error)
    })

    config.url = `${backend_url}/check_interview_sessions/${user.user_id}`;
    axios.request(config).then((res) => {
      if (res.session_ids) {
        settotalRound(res.session_ids);
        setSelectedRound({ value: res.session_ids[res.session_ids.length - 1], label: `Interview ${res.session_ids.length}` });
      }
    }).catch((error) => {
      console.log("error", error)
    })

  }, [])

  const handleRoundSelect = (selectedOption) => {
    setSelectedRound(selectedOption)

    const data = JSON.stringify({ "session_ids": selectedOption?.value, "user": user.user_id });
    const config = {
      method: 'post',
      url: `${backend_url}/get_feedback_details`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }
    dispatch(setLoading(true));
    axios.request(config).then((res) => {
      dispatch(setLoading(false));
      if (res.interview) {
        setroundData(res.interview);
      }
      if (res.overall_feedback && res.overall_feedback[0]) {
        setoverallFeedback(res.overall_feedback[0])
      }
      if (res.suggestion) {
        setoverallSuggestion(res.suggestion);
      }
    }).catch((error) => {
      dispatch(setLoading(false))
      console.log("error", error)
    })
  }

  return (
    <div className='feedback-wrapper'>
      <div className="feedback-upper-section">
        <div className="heading">
          <img src={likeIcon} alt="like" />
          Feedback
        </div>
        <Select
          className='round-select'
          value={selectedRound}
          onChange={handleRoundSelect}
          options={roundOption}
        />
      </div>
      <div className="feedback-chart-section">
        <FeedBackChart roundData={roundData} overallSuggestion={overallSuggestion} />
      </div>
      <div className="overall-feedback">
        <div className='heading'>Overall Feedback</div>
        <div className='description'>Here's your Your Feedback Analysis Based on Your Inputs.</div>
        <textarea disabled value={overallFeedback} name="overallfeedback" className='overallfeedback' id="overallfeedback" rows="8"></textarea>
      </div>
       {selectedRound && selectedRound.value !== totalRound[totalRound.length - 1] ? null : (
        <div className="questions-answers">
          <div className="heading">Detailed Interview Questions, Answers And It’s Feedback</div>
          <div className="description">Here's a detailed feedback of your interview.</div>
          <FeedBackQuestions roundData={roundData} />
        </div>
      )}
    </div>
  )
}

export default Feedback