import React, { useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../../components/Footer";
import "../../scss/Blogs.scss";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/userSlice";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

function BlogPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [blogsData, setBlogsData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [allBlogs, setallBlogs] = useState([]);


  const handlePaginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setLoading(true));
    
    fetch('https://techcrests.com/wp-json/wp/v2/posts?_embed')
      .then(async response => {
        if (!response.ok) {
          const errorData = await response.json().catch(() => null);
          throw new Error(
            errorData?.message || 
            `HTTP error! status: ${response.status}`
          );
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setLoading(false));
        console.log("data", data);

        const formatLink = (url) => {
          const parts = url.split("/").filter(Boolean);
          const lastSegment = parts[parts.length - 1];
          return lastSegment;
        };

        setBlogsData(
          data.map((post) => ({
            id: post.id,
            title: post.title.rendered,
            content: post.content.rendered,
            link: formatLink(post.link),
            image: post._embedded["wp:featuredmedia"]?.[0]?.source_url || "",
            authorName: post._embedded["author"][0]?.name || "",
          }))
        );
      })
      .catch((error) => {
        dispatch(setLoading(false));
        setError(error.message);
        console.error("Error fetching WordPress blog posts:", error);
      });
  }, [dispatch]);

  const getImagePath = (htmlString) => {
    const imgTagRegex = /<img[^>]*>/;
    const imgTagMatch = htmlString.match(imgTagRegex);

    if (imgTagMatch) {
      const imgTag = imgTagMatch[0];
      console.log("Extracted <img> tag:", imgTag);

      const srcRegex = /src=['"]([^'"]*)['"]/;
      const srcMatch = imgTag.match(srcRegex);

      if (srcMatch) {
        return srcMatch[1];
      } else {
        console.log("No src attribute found in the <img> tag");
      }
    } else {
      console.log("No <img> tag found in the HTML");
    }
  };

  return (
    <div className="blogpage-wrapper">
      <NavBar />
      <div className="blogs-header">
        <h1>Blogs</h1>
      </div>
      <div className="container mt-5">
        {error && <p>Error: {error}</p>}
        {blogsData.length > 0 ? (
          <>
            <Row>
              {blogsData.map((blog, index) => (
                <Col key={index} md={6} lg={3} className="mb-4">
                  <Card
                    className="h-100"
                    onClick={() =>
                      navigate(`/blogs/blog/${blog.link}`, {
                        state: { id: blog.id },
                      })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {getImagePath(blog.content) && (
                      <Card.Img src={getImagePath(blog.content)} height={300} />
                    )}
                    <Card.Body>
                      <Card.Title>{blog.title}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <div className="paginate-tabs">
              <ReactPaginate
                onPageChange={handlePaginate}
                pageCount={Math.ceil(allBlogs?.length / postsPerPage)}
                previousLabel={<GrFormPrevious />}
                nextLabel={<GrFormNext />}
                containerClassName={"pagination"}
                pageLinkClassName={"page-number"}
                previousLinkClassName={"page-number"}
                nextLinkClassName={"page-number"}
                activeLinkClassName={"active"}
              />
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default BlogPage;
