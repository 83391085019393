import React, { useEffect, useState } from 'react';
import TickImg from '../../assests/images/tick.png';
import { useNavigate, useSearchParams, Link, useLocation } from 'react-router-dom';
import '../../scss/Subscription.scss';
import { loadStripe } from '@stripe/stripe-js'
import Stripe from 'stripe';
import { useDispatch, useSelector } from 'react-redux';
import { getStripe } from '../../util/stripe';
import { setLoading } from '../../redux/userSlice';
import dayjs from 'dayjs';
import { redirect_url } from '../../util/config';

export default function Subscription() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(state => state.user.user);
  const subscriptionDetail = useSelector(state => state.user.subscriptionDetails);
  const [customerId, setCustomerId] = useState('');

  const [activePlanName, setActivePlanName] = useState('');
  const [activePlanDescription, setActivePlanDescription] = useState('');
  const [activePlanMarketingFeatures, setActivePlanMarketingFeatures] = useState([]);
  const [isPlanActive, setIsPlanActive] = useState(false);
  const [activePlanPrice, setactivePlanPrice] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  useEffect(() => {
    if (subscriptionDetail) {
      console.log(subscriptionDetail);
      setIsPlanActive(subscriptionDetail?.IsPlanActive)
      setActivePlanMarketingFeatures(subscriptionDetail?.ActivePlanMarketingFeatures)
      setstartDate(subscriptionDetail?.startDate)
      setendDate(subscriptionDetail?.endDate)
      setCustomerId(subscriptionDetail?.CustomerId)
      setactivePlanPrice(subscriptionDetail?.activePlanPrice);
      setActivePlanName(subscriptionDetail?.ActivePlanName);
      setActivePlanDescription(subscriptionDetail?.ActivePlanDescription);
    }
  }, [subscriptionDetail]);

  const handleCancelSubsciption = async () => {
    try {
      dispatch(setLoading(true));
      const stripe = getStripe();
      const session = await stripe.billingPortal.sessions.create({
        customer: customerId,
        return_url: `${redirect_url}/subscription`, // Replace with your frontend URL
      });
      if (session.url) {
        window.location.href = session.url
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  }


  return (
    <div className='subscription-wrapper'>

      {isPlanActive && (
        <div className='subscription-plan'>
          <div className='card'>
            <div className="card-body">
              <div className='card-plan-section'>
                <div className='card-heading'>
                  <div className='plan-title'>
                    <h2>Your Subscription</h2>
                    <div className='validity'>
                      {(startDate || endDate) && (<span>({startDate} - {endDate})</span>)}
                    </div>
                  </div>
                  <span className='plan-description'>{activePlanName}</span>
                </div>
                {activePlanPrice && (<span className='pricing'>${activePlanPrice}</span>)}

              </div>
              <div className='card-details'>
                {activePlanMarketingFeatures.map((feature, index) => {
                  return <div key={index} className='plan-property'>
                    <img src={TickImg} alt='img'></img>
                    <p>{feature.name}</p>
                  </div>
                })}
              </div>
              <div className='card-button d-flex flex-column gap-2'>
                <button onClick={handleCancelSubsciption} className='btn'>Manage Subscription</button>
              </div>
            </div>
          </div>
        </div>
      )}

      <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_STRIPE_TABLE_KEY}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
        className="stripe-pricing-table"
        customer-email={user?.email}
        client-reference-id={location?.state?.orderReferenceId ?? ""}
      >
      </stripe-pricing-table>
    </div>
  )
}
