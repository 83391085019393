import React, { useState, useEffect } from "react";
import "../../scss/Home.scss";
import Footer from "../../components/Footer";
import { useNavigate, NavLink, Link, useLocation } from "react-router-dom";
import Growth from "../../assests/images/Growth.png";
import section2Img from "../../assests/images/section2Img.png";
import section3Logo1 from "../../assests/images/section3Logo1.png";
import section3Logo2 from "../../assests/images/section3Logo2.png";
import section3Logo3 from "../../assests/images/section3Logo3.png";
import section3Logo4 from "../../assests/images/section3Logo4.png";
import section4Img from "../../assests/images/section4Img.png";
import section2Logo1 from "../../assests/images/section2Logo1.png";
import section2Logo2 from "../../assests/images/section2Logo2.png";
import section2Logo3 from "../../assests/images/section2Logo3.png";
import section2Logo4 from "../../assests/images/section2Logo4.png";
import section2Logo5 from "../../assests/images/section2Logo5.png";
import tickImg from "../../assests/images/tick.png";
import headerLogo from "../../assests/images/headerLogo.png";
import suitCaseLogo from "../../assests/images/Suitcase.png";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setUser } from "../../redux/userSlice";
import profileDefaultImage from "../../assests/images/sarah.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Modal from "react-bootstrap/Modal";
import videoLink from "../../assests/video/Mindmatch AI Music Changed.mp4";
import axios from "../../util/api";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import Section4 from "../../components/Section4";
import WhoCanUseItSection from "../../components/WhoCanUseItSection";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const [latestBlog, setLatestBlog] = useState(null);

  const handleStripePayment = async (planType, planPrice) => {
    if (user.email) {
      const orderReferenceIdValue = uuidv4();
      localStorage.setItem(
        "activeSubscription",
        JSON.stringify({
          orderReferenceId: orderReferenceIdValue,
          planPrice: planPrice,
        })
      );
      navigate("/subscription", {
        state: { orderReferenceId: orderReferenceIdValue },
      });
    } else {
      sessionStorage.setItem("isSubscribe", true);
      navigate("/login");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch(setUser({}));
    navigate("/login");
  };

  const [activePlan, setActivePlan] = useState("Monthly");
  const [isMobile, setIsMobile] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessWebsite: "",
    companyName: "",
    companyEmail: "",
    description: "",
  });

  const [formError, setFormErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    businessWebsiteError: "",
    companyNameError: "",
    companyEmailError: "",
  });

  useEffect(() => {
    if (location.state && location.state.ClickedOn === "aboutUs") {
      const aboutUsSection = document.getElementById("aboutUs");
      if (aboutUsSection) {
        aboutUsSection.scrollIntoView({ behavior: "smooth" });
      }
    } else if (location.state && location.state.ClickedOn === "pricing") {
      const pricingSection = document.getElementById("pricing");
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: "smooth" });
      }
    } else if (location.state && location.state.ClickedOn === "contactUs") {
      const contactSection = document.getElementById("contactUs");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    dispatch(setLoading(true));
    axios
      .post("/get_all_blogs")
      .then((res) => {
        dispatch(setLoading(false));
        if (res.success) {
          if (res.blogs?.length > 0) {
            setLatestBlog(res.blogs[res.blogs.length - 1]);
          }
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log(error);
      });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (e) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    const errObj = {
      firstNameError: "",
      lastNameError: "",
      businessWebsiteError: "",
      companyNameError: "",
      companyEmailError: "",
    };
    if (!formData.firstName) {
      errObj.firstNameError = "Please enter first name";
      isError = true;
    } else if (!formData.lastName) {
      errObj.lastNameError = "Please enter last name";
      isError = true;
    } else if (!formData.businessWebsite) {
      errObj.businessWebsiteError = "Please enter business website";
      isError = true;
    } else if (!formData.companyName) {
      errObj.companyNameError = "Please enter company name";
      isError = true;
    } else if (!formData.companyEmail) {
      errObj.companyEmailError = "Please enter company email";
      isError = true;
    }
    setFormErrors(errObj);
    if (!isError) {
      console.log("formData-->", formData);
      let contactData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        website: formData.businessWebsite,
        company_name: formData.companyName,
        company_email: formData.companyEmail,
        description: formData.description,
      };

      dispatch(setLoading(true));
      axios
        .post("/contact_sales", contactData)
        .then((res) => {
          dispatch(setLoading(false));
          if (res.success) {
            toast.success(res.message);
            setModalShow(false);
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.log(error);
        });
    }
  };

  const handleSubscription = () => {
    debugger;
    if (user.email) {
      navigate("/interview");
    } else {
      sessionStorage.setItem("isSubscribe", true);
      navigate("/login");
    }
  };

  return (
    <div className="home-page-wrapper">
      <div className="section-1-wrapper">
        <Navbar expand="lg" className="bg-body-tertiary header-wrapper">
          {isMobile && (
            <div className="navbar-brand nav-logo">
              <img className="logo" src={headerLogo} alt="logo"></img>
              <span>
                MIND MATCH AI
                <sup
                  style={{
                    fontSize: "1.2em",
                    verticalAlign: "baseline",
                    lineHeight: "1.3",
                  }}
                >
                  ™
                </sup>
              </span>
            </div>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {!isMobile && (
                <div className="navbar-brand nav-logo">
                  <img className="logo" src={headerLogo} alt="logo"></img>
                  <span>
                    MIND MATCH AI
                    <sup
                      style={{
                        fontSize: "1.2em",
                        verticalAlign: "baseline",
                        lineHeight: "1.3",
                      }}
                    >
                      ™
                    </sup>
                  </span>
                </div>
              )}
              <div className="nav-items">
                <Link
                  to=""
                  onClick={() =>
                    document
                      .getElementById("aboutUs")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  About Us
                </Link>
                <NavLink
                  to=""
                  onClick={() =>
                    document
                      .getElementById("pricing")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  Pricing
                </NavLink>
                <NavLink to="/blogs">Blogs</NavLink>
                <NavLink
                  to=""
                  onClick={() =>
                    document
                      .getElementById("demoSection")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                >
                  Demo
                </NavLink>
              </div>
              {!user.email ? (
                <div className="button-section">
                  <button
                    className="btn contact-sales"
                    onClick={handleSubscription}
                  >
                    Try for free
                  </button>
                  <button
                    className="btn sign-in"
                    onClick={() => navigate("/login")}
                  >
                    Sign In
                  </button>
                </div>
              ) : (
                <div className="user-profile-section">
                  <NavDropdown
                    id="basic-nav-dropdown"
                    title={`${user.first_name} ${user.last_name}`}
                  >
                    <NavDropdown.Item onClick={() => navigate("/profile")}>
                      Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                  {!isMobile && (
                    <img
                      src={
                        user.profileUrl
                          ? `data:image/png;base64,${user.profileUrl}`
                          : profileDefaultImage
                      }
                      alt="profile"
                    ></img>
                  )}
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="section-1-content">
          <div className="content-left">
            <h1 className="heading">Ace Your Next Interview</h1>
            <h2 className="h2-heading">
              with our AI-based mock interview platform
            </h2>
            <h3 className="description">
              Prepare for Success with Cutting-Edge AI Technology and Realistic
              Interview Scenarios. Boost Your Confidence and Skills Today with
              Personalized Feedback and Practice.
            </h3>
            <div className="buttons-section">
              <button className="btn try-free" onClick={handleSubscription}>
                Try for free
              </button>
              <button
                className="btn learn-more"
                onClick={() =>
                  document
                    .getElementById("contactUs")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="content-right">
            {/* <video
              // src={videoLink}
              src={"https://youtu.be/se0OI4Vy23E"}
              className="homepage-video"
              controls
              preload="auto"
              controlsList="nodownload"
            ></video> */}
            <iframe
              width="574"
              height="365"
              src="https://www.youtube.com/embed/se0OI4Vy23E"
              title="Ace your next interview-with our AI-based mock interview platform"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="section-2-wrapper" id="aboutUs">
        <div className="section-2-content">
          <div className="left-section">
            <div className="upper">
              <span>ABOUT US</span>
              <h2>
                We're more than just a platform – we're your partner in career
                excellence!
              </h2>
            </div>
            <div className="lower">
              <img src={section2Img} alt="img"></img>
            </div>
          </div>
          <div className="right-section">
            <div className="about-us-description">
              <div>
                <div className="logo">
                  <img src={section2Logo1} alt="logo"></img>
                </div>
              </div>
              <div className="content">
                <h5>Endless Practice Opportunities</h5>
                <p>
                  Benefit from unlimited mock interviews, enabling you to refine
                  your technique to perfection at your own pace.
                </p>
              </div>
            </div>
            <div className="about-us-description">
              <div>
                <div className="logo">
                  <img src={section2Logo2} alt="logo"></img>
                </div>
              </div>
              <div className="content">
                <h5>World-Class User Experience</h5>
                <p>
                  Enjoy a user-friendly platform designed to optimize your mock
                  interview preparation.
                </p>
              </div>
            </div>
            <div className="about-us-description">
              <div>
                <div className="logo">
                  <img src={section2Logo3} alt="logo"></img>
                </div>
              </div>
              <div className="content">
                <h5>Targeted Skill Enhancement</h5>
                <p>
                  Refine your skills by tackling common interview queries or
                  honing in on specific areas such as communication,
                  problem-solving, or storytelling
                </p>
              </div>
            </div>
            <div className="about-us-description">
              <div>
                <div className="logo">
                  <img src={section2Logo4} alt="logo"></img>
                </div>
              </div>
              <div className="content">
                <h5>Progress Tracking</h5>
                <p>
                  Stay informed about your development journey with detailed
                  progress reports, allowing you to track improvements over time
                  and fine-tune your approach accordingly
                </p>
              </div>
            </div>
            <div className="about-us-description">
              <div>
                <div className="logo">
                  <img src={section2Logo5} alt="logo"></img>
                </div>
              </div>
              <div className="content">
                <h5>Tailored Feedback Reports</h5>
                <p>
                  Get comprehensive feedback reports post each practice session.
                  Our AI meticulously analyzes your responses, pinpointing
                  strengths and areas for growth while offering tailored
                  suggestions for improvement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-3-wrapper" id="contactUs">
        <div className="section-3-body">
          <div className="section-heading">
            <span>OUR IMPACT</span>
            <h2>How We Help</h2>
          </div>
          <div className="cards-container">
            <div className="card">
              <img src={section3Logo1} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">Refine Your Interview Skills</span>
                <p className="card-description">
                   Elevate your ability to respond effectively to interview
                  questions, leaving a lasting impact on potential employers
                </p>
              </div>
            </div>
            <div className="card">
              <img src={section3Logo2} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">
                  Boost Confidence, Minimize Stress
                </span>
                <p className="card-description">
                  Overcome interview jitters with authentic practice sessions
                  and tailored guidance
                </p>
              </div>
            </div>
            <div className="card">
              <img src={section3Logo4} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">Distinguish Yourself</span>
                <p className="card-description">
                  Cultivate standout interview techniques to set yourself apart
                  and secure your desired position
                </p>
              </div>
            </div>
            <div className="card">
              <img src={section3Logo3} alt="logo"></img>
              <div className="card-details">
                <span className="card-title">Efficient & Cost-Effective</span>
                <p className="card-description">
                  Optimize your preparation without the hefty expense of
                  traditional interview coaching, accessing AI-driven practice
                  at your convenience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Home">
        {/* Other components */}
        <Section4 />
      </div>

      <div id="demoSection" class="demo-section">
        <div class="text-content">
          <h2>Product Demo</h2>
          <p>See a Brief Demonstration of How Our Product Works</p>
          <a href="https://youtu.be/EZpEdUOXlsw" class="btn" target="_blank">
            Click to watch the video
          </a>
        </div>
        <div class="video-content">
          <div class="youtube-video-wrapper">
            <iframe
              src="https://www.youtube.com/embed/EZpEdUOXlsw?rel=0&modestbranding=1&controls=1&showinfo=0&iv_load_policy=3&autohide=1"
              title="Product Walkthrough"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className="Home">
        {/* Other components */}
        <WhoCanUseItSection />
      </div>

      <div className="section-5-wrapper" id="pricing">
        <div className="section-5-body">
          <div className="heading">
            <span>SUBSCRIPTIONS</span>
            <h2>Our Pricing</h2>
          </div>
          <div className="cards-section">
            {/* <div className='monthly-yearly-plans'>
              <button className={`btn £{activePlan === 'Monthly' ? 'active' : ''}`} onClick={() => setActivePlan('Monthly')}>Monthly</button>
              <button className={`btn £{activePlan === 'Yearly' ? 'active' : ''}`} onClick={() => setActivePlan('Yearly')}>Yearly</button>
            </div> */}
            <div className="cards">
              <div className="card">
                <div className="card-body">
                  <div className="card-plan-section">
                    <div className="card-heading">
                      <span className="plan-title">Basic Plan</span>
                      <span className="plan-description">Free Trial</span>
                    </div>
                    <span className="pricing">£0</span>
                  </div>
                  <div className="card-details">
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>1 user</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>2 free interviews</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Introduction Interview round</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Real-time feedback</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Suggested answers</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Overall score</p>
                    </div>
                  </div>
                  <div className="card-button d-flex flex-column gap-2">
                    <button className="btn" onClick={handleSubscription}>
                      Try for free
                    </button>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="card-plan-section">
                    <div className="card-heading">
                      <span className="plan-title">Monthly Plan</span>
                      <span className="plan-description"></span>
                      {/* <span className='plan-description'>Enjoy Basic ammunites</span> */}
                    </div>
                    <span className="pricing">
                      £12<span className="plan-type">/Monthly</span>
                    </span>
                  </div>
                  <div className="card-details">
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>1 user</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Unlimited interview</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Real-time feedback</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Suggested answers</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Overall score</p>
                    </div>
                    {/* <div className='plan-property'>
                      <img src={tickImg} alt='img'></img>
                      <p>60MB monthly upload limit</p>
                    </div> */}
                  </div>
                  <div className="card-button d-flex flex-column gap-2">
                    <button
                      className="btn"
                      onClick={() => handleStripePayment("Monthly Plan", "12")}
                    >
                      Subscription
                    </button>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="card-plan-section">
                    <div className="card-heading">
                      <span className="plan-title">Quarterly Plan</span>
                      <span className="plan-description">(3 Months)</span>
                    </div>
                    <span className="pricing">
                      £9.6<span className="plan-type">/Monthly</span>
                    </span>
                  </div>
                  <div className="card-details">
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>1 user</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Unlimited interview</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Real-time feedback</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Suggested answers</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Overall score</p>
                    </div>
                    {/* <div className='plan-property'>
                      <img src={tickImg} alt='img'></img>
                      <p>60MB monthly upload limit</p>
                    </div> */}
                  </div>
                  <div className="card-button d-flex flex-column gap-2">
                    <button
                      className="btn"
                      onClick={() =>
                        handleStripePayment("Quarterly Plan", "28.8")
                      }
                    >
                      Subscription
                    </button>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="card-plan-section">
                    <div className="card-heading">
                      <span
                        className="plan-title"
                        style={{ textWrap: "nowrap" }}
                      >
                        University/ Enterprise
                      </span>
                      <span className="plan-description"></span>
                      {/* <span className='plan-description'>Enjoy Basic ammunites</span> */}
                    </div>
                    <span className="pricing"></span>
                  </div>
                  <div className="card-details">
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>White-Label Options</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>
                        Integration Capabilities-with university systems (e.g.,
                        LMS, career services portals)
                      </p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Flexible Pricing Plans</p>
                    </div>
                    <div className="plan-property">
                      <img src={tickImg} alt="img"></img>
                      <p>Continuous Support</p>
                    </div>
                    {/* <div className='plan-property'>
                      <img src={tickImg} alt='img'></img>
                      <p>25MB maximum note</p>
                    </div>
                    <div className='plan-property'>
                      <img src={tickImg} alt='img'></img>
                      <p>60MB monthly upload limit</p>
                    </div> */}
                  </div>
                  <div className="card-button">
                    <button className="btn" onClick={() => setModalShow(true)}>
                      Contact Sales
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {latestBlog && (
        <div className="section-6-wrapper">
          <div className="section-6-body">
            <div className="left">
              <div className="blog-header-section">
                <span>LATEST BLOGS</span>
                <h6 className="blog-heading">{latestBlog.title}</h6>
                <p className="blog-description">{latestBlog.description}</p>
              </div>
              {/* <div className='blogs'>
                <div className='blog'>
                  <HiArrowDownRight />
                  <div className='blog-content'>
                    <div className='read-time'>Lorem Ipsum| 12 Min Read</div>
                    <p className='blog-title'>Lorem ipsum dolor sit amet consectetur. Lectus tristique auctor nascetur eros magna</p>
                  </div>
                </div>
                <div className='blog'>
                  <HiArrowDownRight size={24} />
                  <div className='blog-content'>
                    <div className='read-time'>Lorem Ipsum| 12 Min Read</div>
                    <p className='blog-title'>Lorem ipsum dolor sit amet consectetur. Lectus tristique auctor nascetur eros magna</p>
                  </div>
                </div>
              </div> */}
              <button
                onClick={() => navigate("blogs")}
                className="btn view-more-btn"
              >
                View More
              </button>
            </div>
            <div className="right">
              <img
                src={`data:image/png;base64,${latestBlog?.image}`}
                alt="img"
              ></img>
              {/* <div className='img-overlay-content'>
                <p>Lorem ipsum dolor sit amet consectetur. Lectus tristique auctor nascetur eros magna</p>
                <span>Tips & Tricks | 5 Min Read</span>
              </div> */}
            </div>
          </div>
        </div>
      )}
      <Footer
        setModalShow={setModalShow}
        handleSubscription={handleSubscription}
      />
      <Modal
        className="end-Interview-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <h3 className="text-center fw-bold">Contact our sales team</h3>
            <div className="firstName-field field">
              <label htmlFor="firstName">
                First Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.firstNameError}
              </span>
            </div>
            <div className="last-name-field field">
              <label htmlFor="lastName">
                Last Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.lastNameError}
              </span>
            </div>
            <div className="business-name-field field">
              <label htmlFor="businessWebsite">
                Business Website<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="businessWebsite"
                name="businessWebsite"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.businessWebsiteError}
              </span>
            </div>
            <div className="company-name-field field">
              <label htmlFor="companyName">
                Company Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.companyNameError}
              </span>
            </div>
            <div className="company-email-field field">
              <label htmlFor="companyEmail">
                Company Email<span className="text-danger">*</span>
              </label>
              <input
                type="email"
                id="companyEmail"
                name="companyEmail"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.companyEmailError}
              </span>
            </div>
            <div className="description-field field">
              <label htmlFor="description">Description(Optional)</label>
              <textarea
                rows={5}
                id="description"
                name="description"
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="button-section">
              <button
                type="cancel"
                className="mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  setModalShow(false);
                }}
              >
                Cancel
              </button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
