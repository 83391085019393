import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/userSlice';
import defaultBanner from '../assests/images/banner-default.jpg';
function BlogCard({ imgurl, description, heading, blogId, pageType, refreshBlog }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleBlogDelete = () => {
        dispatch(setLoading(true));
        axios.post(`/delete_blog/${blogId}`).then(res => {
            dispatch(setLoading(false));
            if (res.success) {
                refreshBlog(blogId);
            }
        }).catch(error => {
            dispatch(setLoading(false));
            console.log(error);
        })
    }
    return (
        <div className='card'>
            <div className='card-top' style={{ position: 'relative' }}>
                <img src={imgurl ? `data:image/png;base64,${imgurl}`: defaultBanner} alt='img'></img>
            </div>
            <div className='card-description'>
                {/* <div className='read-time-section'>
                    <span>Lorem Ipsum</span>
                    <span className='read-time'>5 min read</span>
                </div> */}
                <div className='description-section'>
                    <h4 className='heading'>{heading}</h4>
                    <p className='description'>{description}</p>
                </div>
                {pageType === 'admin' ? (
                    <div style={pageType === 'admin' ? { display: 'flex', justifyContent: 'space-between' } : {}}>
                        <button onClick={() => navigate(`/admin/editBlog/${blogId}`)}>Edit</button>
                        <button onClick={handleBlogDelete}>Delete</button>
                    </div>) : (
                    <div>
                        <button onClick={() => navigate(`/blogs/blog/${blogId}`)}>Read More</button>
                    </div>
                )}

            </div>
        </div>
    )
}

export default BlogCard