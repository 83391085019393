import React, { useEffect } from 'react';
import Footer from '../../components/Footer';
import '../../scss/PrivacyPolicy.scss';
import NavBar from '../NavBar/NavBar';

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <div className='privacy-policy-wrapper'>
            <NavBar />
            <div className='privacy-policy-header'>
                <h1>Privacy Policy</h1>
            </div>
            <div className="privacy-policy-content">
              <p>Thank you for choosing to use MIND MATCH AI LIMITED services. This Privacy
                Policy outlines how we collect, use, disclose, and safeguard your personal
                information when you visit our website www.mindmatchai.com and use our services.</p>
              <p>By using our website and services, you consent to the collection and use of your
                personal information as described in this Privacy Policy. If you do not agree with the
                terms of this Privacy Policy, please do not access or use our website and services.</p>
              <h2>I. Information We Collect</h2>
              <p>We may collect personal information from you when you interact with our website or use our services. The types of personal information we may collect include:
              </p>
              <p>Contact information (e.g., name, email address)</p>
              <p>Login credentials</p>
              <p>Payment information</p>
              <p>Communications preferences</p>
              <p>Usage data (e.g., IP address, browser type, referring/exit pages, clicks)</p>
              <p>Other information you provide voluntarily</p>

              <h2>II. How We Use Your Information</h2>
              <p>We may use the personal information we collect for various purposes, including:</p>
              <p>Providing and maintaining our services</p>
              <p>Communicating with you</p>
              <p>Communicating with you</p>
              <p>Personalizing your experience</p>
              <p>Processing transactions</p>
              <p>Analyzing usage of our services</p>
              <p>Improving our services and website</p>
              <p>Preventing fraud and ensuring security</p>
              <p>Complying with legal obligations</p>

              <h2>III. Disclosure of Your Information</h2>
              <p>We may disclose your personal information to third parties in the following circumstances:</p>
              <p>With your consent</p>
              <p>To service providers and business partners who assist us in operating our website and providing our services</p>
              <p>To comply with legal obligations or respond to lawful requests from government authorities</p>
              <p>To protect our rights, property, or safety, or the rights, property, or safety of others</p>

              <h2>IV. International Data Transfers</h2>
              <p>Your personal information may be transferred to and processed in countries other than your own. By using our services, you consent to the transfer of your personal information to these countries.</p>

              <h2>V. Data Retention</h2>
              <p>We will retain your personal information for as long as necessary to fulfill the
                purposes outlined in this Privacy Policy, unless a longer retention period is required
                or permitted by law.</p>

              <h2>VI. Your Rights</h2>
              <p>You may have certain rights regarding your personal information, including:</p>
              <p>The right to access, update, or delete your personal information</p>
              <p>The right to object to processing of your personal information</p>
              <p>The right to restrict processing of your personal information</p>
              <p>The right to data portability</p>
              <p>To exercise these rights, please contact us using the contact information provided below.</p>

              <h2>VII. Children’s Privacy</h2>
              <p>Our website and services are not directed to children under the age of 13. We do not
                knowingly collect personal information from children under the age of 13. If you are a
                parent or guardian and believe that your child has provided us with personal
                information, please contact us so that we can take appropriate action.</p>

              <h2>VIII. Changes to This Privacy Policy</h2>
              <p>We may update this Privacy Policy from time to time. Any changes we make to this
                Privacy Policy will be posted on this page. We encourage you to review this Privacy Policy periodically for any updates.</p>

              <h2>IX. Contact Us</h2>
              <p>If you have any questions or concerns about this Privacy Policy or our privacy
                practices, please contact us at admin@mindmatch.com</p>

              <h2>X. Governing Law</h2>
              <p>This Privacy Policy is governed by and construed in accordance with the laws of the United Kingdom.</p>
              <p>By using our website and services, you acknowledge that you have read and
                understood this Privacy Policy and consent to the collection, use, and disclosure of
                your personal information as described herein.</p>
          </div>
            <Footer />
        </div>
      )
}

export default PrivacyPolicy