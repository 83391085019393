import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Footer from "../../components/Footer";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/userSlice";
import "../../scss/Blogs.scss";

function BlogDetailPage() {
  const location = useLocation();
  const { id } = location.state || {};
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch(`https://techcrests.com/wp-json/wp/v2/posts/${id}?_embed`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        dispatch(setLoading(false));
        console.log("data", data);
        setBlogData(data);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.error("Error fetching WordPress blog posts:", error);
      });

  }, [dispatch]);

  return (
    <div className="blogpage-wrapper">
      <NavBar />
      <div className="blogpage-content">
        {Object.keys(blogData ?? {}).length > 0 ? (
          <>
            <h2>{blogData?.title?.rendered}</h2>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: blogData?.content?.rendered }}
            />
          </>
        ) : (
          <p>Loading blog details...</p>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default BlogDetailPage;
