import React, { useEffect, useState } from 'react'
import { Chart, ArcElement } from 'chart.js';
import Star from '../../assests/images/star.png';
import DoughnutChart from '../DoughnutChart';
import { Doughnut } from 'react-chartjs-2';
Chart.register(ArcElement);

export default function FeedBackChart({ roundData,overallSuggestion }) {

  const [overallScore, setoverallScore] = useState(0)
  const [introPercentage, setintroPercentage] = useState(0)
  const [technicalPercentage, settechnicalPercentage] = useState(0)
  const [managerialPercentage, setmanagerialPercentage] = useState(0)
  const [humanResourcePercentage, sethumanResourcePercentage] = useState(0)
  const introColor = '#FF8266';
  const technicalColor = '#3B76E3';
  const managerialColor = '#967DE2';
  const hrColor = '#60DC92';

  useEffect(() => {
    let totalScore = 0;
    roundData?.forEach(element => {
      totalScore += parseFloat(element.score)
      if (element.roundname === 'Introduction') {
        setintroPercentage(parseFloat(element.score))
      } else if (element.roundname === 'Technical') {
        settechnicalPercentage(parseFloat(element.score))
      }
      else if (element.roundname === 'Managerial/ Behavioural') {
        setmanagerialPercentage(parseFloat(element.score))
      }
      else if (element.roundname === 'Human Resources') {
        sethumanResourcePercentage(parseFloat(element.score))
      }
    });
    setoverallScore(parseFloat((totalScore / roundData.length).toFixed(2)) || 0);
  }, [roundData])



  // const data = {
  //     labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4'],
  //     datasets: [
  //         {
  //             data: [100],
  //             backgroundColor: ['#60DC92'],
  //             borderWidth: 20,
  //         },
  //         {
  //             data: [100],
  //             backgroundColor: ['#3B76E3'],
  //             borderWidth: 20,
  //         },
  //         {
  //             data: [100],
  //             backgroundColor: ['#967DE2'],
  //             borderWidth: 20,
  //         },
  //         {
  //             data: [100],
  //             backgroundColor: ['#FF8266'],
  //             borderWidth: 20,
  //         },
  //     ],
  // };

  let percentage1 = overallScore;
  let percentage2 = overallScore;

  const data1 = {
    datasets: [
      {
        label: 'Introduction',
        data: [introPercentage, 100 - introPercentage],
        backgroundColor: [
          introColor,
          '#0101011a'
        ],
        hoverBackgroundColor: [
          introColor,
          '#0101011a'
        ]
      },
      {
        // Add an empty dataset as a separator
        data: [0, 100],
        backgroundColor: ["transparent"],
        hoverBackgroundColor: ["transparent"]
      },
      {
        label: 'Technical',
        data: [technicalPercentage, 100 - technicalPercentage],
        backgroundColor: [
          technicalColor,
          '#0101011a'
        ],
        hoverBackgroundColor: [
          technicalColor,
          '#0101011a'
        ]
      },
      {
        // Add an empty dataset as a separator
        data: [0, 100],
        backgroundColor: ["transparent"],
        hoverBackgroundColor: ["transparent"]
      },
      {
        label: 'Managerial',
        data: [managerialPercentage, 100 - managerialPercentage],
        backgroundColor: [
          managerialColor,
          '#0101011a'
        ],
        hoverBackgroundColor: [
          managerialColor,
          '#0101011a'
        ]
      },
      {
        // Add an empty dataset as a separator
        data: [0, 100],
        backgroundColor: ["transparent"],
        hoverBackgroundColor: ["transparent"]
      },
      {
        label: 'Human Resource',
        data: [humanResourcePercentage, 100 - humanResourcePercentage],
        backgroundColor: [
          hrColor,
          '#0101011a'
        ],
        hoverBackgroundColor: [
          hrColor,
          '#0101011a'
        ]
      }
    ],
  };

  const options = {
    cutout: '20%',
    spacing: 0
  }

  // const chartContainerStyle = {
  //     width: '100%',
  //     height: 'auto',
  //     maxWidth: '460px',
  //     margin: 'auto',
  //     position: 'relative'
  // };
  return (
    <div className='chart-wrapper'>
      <div className="chart">
        {/* <div style={chartContainerStyle}>
                    <Pie data={data} />
                </div> */}
        <div style={{ maxWidth: "460px", height: "auto", position: "relative" }}>
          <Doughnut data={data1} options={options} style={{ transform: "rotateY(180deg)" }} />
          <span style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50% , -50%)", fontSize: "14px", fontWeight: "800" }}>{overallScore}%</span>
        </div>
      </div>

      {/* <div className="chart">
                <div style={chartContainerStyle}>
                    <Pie data={data2} />
                </div>
            </div> */}

      <div className="overall-score-section">
        <div className='overall-score-header'>
          <div className='percentage'>
            <img src={Star} alt='icon'></img>
            <span>{overallScore}%</span>
          </div>
          <h6>Overall Score</h6>
        </div>
        <div className='all-rounds-score'>
          <div className='upper-section'>
            {roundData.map((item, index) => {
              const color = item.roundname === 'Introduction' 
              ? introColor 
              : item.roundname === 'Managerial/ Behavioural' 
              ? managerialColor 
              : item.roundname === 'Technical' 
              ? technicalColor 
              : hrColor;
              return (<div key={index} className='left'>
                <DoughnutChart percentage={parseFloat(item.score)} color={color}  />
                <div className='description'>
                  <h6>{item.roundname}</h6>
                  <p>{item.all_feedback}</p>
                </div>
              </div>)
            })}
            {/* <div className='left'>
              <DoughnutChart percentage={introPercentage} color={introColor} />
              <div className='description'>
                <h6>Introduction</h6>
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </div>
            </div>
            <div className='right'>
              <DoughnutChart percentage={managerialPercentage} color={managerialColor} />
              <div className='description'>
                <h6>Managerial/Behavioral</h6>
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </div>
            </div> */}
          </div>
          {/* <div className='lower-section'>
            <div className='left'>
              <DoughnutChart percentage={technicalPercentage} color={technicalColor} />
              <div className='description'>
                <h6>Technical</h6>
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </div>
            </div>
            <div className='right'>
              <DoughnutChart percentage={humanResourcePercentage} color={hrColor} />
              <div className='description'>
                <h6>Human Resource</h6>
                <p>Lorem ipsum dolor sit amet consectetur.</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className='suggestions-to-improve'>
          <h6>Suggestions to Improve</h6>
          <p>{overallSuggestion}</p>
        </div>
      </div>
    </div>
  )
}
