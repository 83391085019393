import { createSlice } from '@reduxjs/toolkit'
const user = localStorage.getItem("user");
const selectedRounds = sessionStorage.getItem("selectedRounds");

const initialState = {
    loading: false,
    user: JSON.parse(user) || {},
    userProfile: {},
    selectedRounds: JSON.parse(selectedRounds) || [],
    textVisible: false,
    subscriptionDetails: {},
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload
        },

        setUserProfile: (state, action) => {
            state.userProfile = action.payload;
        },

        setSelectedRounds: (state, action) => {
            state.selectedRounds = action.payload
        },

        setTextVisible: (state, action) => {
            state.textVisible = action.payload
        },

        setSubscriptionDetails: (state, action) => {
            state.subscriptionDetails = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLoading, setUser, setUserProfile, setSelectedRounds, setTextVisible, setSubscriptionDetails } = userSlice.actions

export default userSlice.reducer