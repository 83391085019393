import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../components/SideBar";
import "../../scss/Dashboard.scss";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../util/api";
import {
  setSubscriptionDetails,
  setUser,
  setUserProfile,
} from "../../redux/userSlice";
import { getStripe } from "../../util/stripe";
import dayjs from "dayjs";

export default function Dashboard() {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const getCustomerByEmail = async () => {
    try {
      const stripe = getStripe();
      const customer = await stripe.customers.list({
        email: user?.email,
        limit: 1,
      });

      if (customer.data && customer.data[0] && customer.data[0].id) {
        const subscriptions = await stripe.subscriptions.list({
          customer: customer.data[0]?.id,
        });
        let obj = { planType: "free" };
        const activeSubscription = subscriptions.data.find(
          (item) => item.status === "active" || item.status === "trialing"
        );
        console.log(activeSubscription);
        if (
          activeSubscription &&
          activeSubscription.plan &&
          activeSubscription.plan.active
        ) {
          var productId = activeSubscription.plan.product;
          const product = await stripe.products.retrieve(productId);
          if (activeSubscription.plan.amount) {
            obj.activePlanPrice = activeSubscription.plan.amount / 100;
            if (activeSubscription.plan.amount === 0) {
              obj.planType = "free";
            } else {
              obj.planType = "premium";
            }
          }
          if (activeSubscription.current_period_start) {
            obj.startDate = dayjs(
              activeSubscription.current_period_start * 1000
            ).format("DD MMMM, YYYY");
          }
          if (activeSubscription.current_period_end) {
            obj.endDate = dayjs(
              activeSubscription.current_period_end * 1000
            ).format("DD MMMM, YYYY");
          }
          if (product) {
            obj.ActivePlanName = product.name;
            obj.ActivePlanDescription = product.description;
            obj.ActivePlanMarketingFeatures = product.marketing_features;
            obj.IsPlanActive = product.active;

            let productName = product.name?.toLowerCase();
            if (
              productName.includes("free") ||
              productName?.includes("basic") ||
              productName?.includes("trial")
            ) {
              obj.planType = "free";
            } else {
              obj.planType = "premium";
            }
          }
        }
        obj.CustomerId = customer.data[0].id;
        dispatch(setSubscriptionDetails(obj));
      } else {
        dispatch(setSubscriptionDetails({}));
      }
    } catch (error) {
      console.error("Error fetching customer:", error);
    }
  };

  useEffect(() => {
    if (user?.user_id) {
      axios
        .post(`/get_user_profile/${user?.user_id}`)
        .then((res) => {
          if (res.success) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...user,
                profileUrl: res.profile?.profile_picture,
                first_name: res.profile?.first_name,
                last_name: res.profile?.last_name,
              })
            );
            dispatch(
              setUser({
                ...user,
                profileUrl: res.profile?.profile_picture,
                first_name: res.profile?.first_name,
                last_name: res.profile?.last_name,
              })
            );
            dispatch(setUserProfile(res.profile));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      getCustomerByEmail();
    }
  }, []);

  return (
    <>
      <div className="dashboard-container">
        <div className="dashboard-content">
          <div className="sidebar">
            <SideBar />
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
}
