import React, { useEffect, useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setLoading, setUser } from '../../redux/userSlice';
import { toast } from 'react-hot-toast';
import axios from '../../util/api';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

export default function Login() {
    const [formData, setFormData] = useState({ email: '', Password: '' });
    const [formErrors, setFormErrors] = useState({ emailError: '', passwordError: '' });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [uniqueId, setUniqueId] = useState('');

    useEffect(() => {
        const getOrCreateUniqueId = () => {
            let id = localStorage.getItem('uniqueId');
            if (!id) {
                id = Cookies.get('uniqueId');
                if (!id) {
                    id = uuidv4();
                }
                localStorage.setItem('uniqueId', id);
                Cookies.set('uniqueId', id, { expires: 365 });
            }
            setUniqueId(id);
        };
        getOrCreateUniqueId();
    }, []);

    const validateLoginForm = () => {
        var errors = { emailError: '', passwordError: '' };
        let { email, Password } = formData;
        var isError = false;
        if (!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            errors.emailError = 'Enter Valid email'
            isError = true;
        }

        if (Password.length < 4) {
            errors.passwordError = 'Enter valid password'
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return ({
                ...prevData,
                [name]: value
            })
        })
    }

    function handleType() {
        setShowPassword(!showPassword)
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!validateLoginForm()) {
            const data = {
                email: formData.email,
                password: formData.Password,
                device_id: uniqueId
            }
            dispatch(setLoading(true));
            axios.post('/login', data).then((res) => {
                dispatch(setLoading(false));
                if (res.success) {
                    const nameArray = res.name?.split(' ');
                    let user = {
                        first_name: nameArray[0] || '',
                        last_name: nameArray[1] || '',
                        email: res.email,
                        user_id: res.user_id
                    }
                    localStorage.setItem('user', JSON.stringify(user));
                    dispatch(setUser(user));
                    let isSubscribe = sessionStorage.getItem('isSubscribe');
                    if (res.email.toLowerCase() === 'admin@mindmatchai.com') {
                        navigate('/admin');
                    } else if (isSubscribe === 'true' || isSubscribe === true) {
                        sessionStorage.removeItem('isSubscribe');
                        navigate('/subscription')
                    } else if (res.has_profile) {
                        navigate('/interview')
                    } else (
                        navigate('/profile')
                    )
                    toast.success(res.message)
                }
            }).catch((error) => {
                toast.error(error.response.data.error)
                dispatch(setLoading(false));
            })
        }
    }
    return (
        <>
            <div className="login-form-container">
                <h5 className='login-heading'>Sign In with your credentials</h5>
                <form className='login-form' onSubmit={handleSubmit}>
                    <div className='email_section'>
                        <label htmlFor="email">Email</label>
                        <input type='email' id='email' name='email' onChange={handleChange} value={formData.email} autoComplete='off'></input>
                    </div>
                    <span className='form-error'>{formErrors.emailError}</span>
                    <div className='password_section mt-3'>
                        <label htmlFor="password">Password</label>
                        <input type={showPassword ? 'text' : 'password'} id='password' name='Password' onChange={handleChange} value={formData.Password}></input>
                        {!showPassword ? (<BiHide className='hide_show' onClick={handleType} />) : (<BiShow className='hide_show' onClick={handleType} />)}
                    </div>
                    <span className='form-error'>{formErrors.passwordError}</span>
                    <button className='login_btn'>Sign In</button>
                </form>
            </div>
        </>
    )
}
