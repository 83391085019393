import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer';
import NavBar from '../NavBar/NavBar';
import '../../scss/EditBlogPage.scss';
import CardImg from '../../assests/images/blogImg1.png'
import { useRef } from 'react';
import cameraIcon from '../../assests/images/camera-icon.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import defaultBanner from '../../assests/images/banner-default.jpg'
import axios from '../../util/api';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';

function EditBlogPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { blogId } = useParams();
    const fileInputRef = useRef(null);
    const [profileImage, setProfileImage] = useState('');
    const [profileURL, setProfileURL] = useState('');
    const location = useLocation();
    const pathArry = location.pathname.split('/');
    const adminPage = pathArry[pathArry.length - 1]
    const [blogData, setblogData] = useState({ title: '', description: '' });
    const [imageChange, setimageChange] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            setProfileURL(imageUrl);
            setimageChange(true);
            setProfileImage(file);
        }
    };

    const handleImageSubmit = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    const handleSaveBlog = () => {
        let data = { ...blogData, image: profileImage }
        dispatch(setLoading(true));
        if (blogId) {
            axios.post(`/update_blog/${blogId}`, data).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    toast.success(res.message);
                    navigate('/admin');
                }
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error);
            })
        } else {
            axios.post('/create_blog', data).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    toast.success(res.message);
                    navigate('/admin');
                }
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error);
            })
        }
    }

    useEffect(() => {
        if (blogId) {
            dispatch(setLoading(true));
            axios.post(`/get_blog/${blogId}`).then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    setblogData({ title: res.blog?.title, description: res.blog?.description });
                    setProfileURL(res.blog?.image)
                }
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error);
            })
        }
    }, [])


    return (
        <>
            <NavBar />
            <div className='edit-page-form'>
                <div className='edit-img-section'>
                    {adminPage === 'newBlog' ? (
                        <img className={`profile-img ${profileURL ? '' : 'new-blog'}`} src={profileURL ? profileURL : defaultBanner} alt="profile" />
                    ) : (
                        <img className={`profile-img ${profileURL ? '' : 'new-blog'}`} src={imageChange ? (profileURL) : (profileURL ? `data:image/png;base64,${profileURL}` : defaultBanner)} alt="profile" />
                    )}
                    <img className='camera-icon' src={cameraIcon} onClick={handleImageSubmit} alt="camera" />
                    <input
                        type='file'
                        id='profile_pic'
                        accept='image/*'
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        hidden
                    />
                </div>
                <input type='text' value={blogData.title} onChange={(e) => setblogData({ ...blogData, [e.target.name]: e.target.value })} className='card-title' name='title' placeholder='Title'></input>
                <textarea className='card-description' value={blogData.description} onChange={(e) => setblogData({ ...blogData, [e.target.name]: e.target.value })} name='description' placeholder='Description' rows={6}></textarea>
                <div className='btn-section'>
                    <button onClick={handleSaveBlog}>Save</button>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default EditBlogPage