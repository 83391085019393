import Footer from "../../components/Footer";
import "../../scss/Affiliate.scss";
import NavBar from "../NavBar/NavBar";
import BloggerIcon from "../../assests/images/EditorCircle.svg";
import EducatorsIcon from "../../assests/images/Resources.svg";
import YoutuberIcon from "../../assests/images/DeviceVideoCamera.svg";
import UserExpertIcon from "../../assests/images/UserExpert.svg";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import axios from '../../util/api';
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/userSlice";
import toast from "react-hot-toast";
function Affiliate() {
  const [modalShow, setModalShow] = useState(false);
  const cards = [
    {
      title: "Bloggers and writers",
      description:
        "Boost your blog's revenue by adding MIND MATCH AI affiliate links.",
      icon: BloggerIcon,
    },
    {
      title: "Educators",
      description:
        "Turn your passion into income by adding affiliate links to your courses, tutorials, and classes.",
      icon: EducatorsIcon,
    },
    {
      title: "YouTubers",
      description:
        "Earn more by adding affiliate links to your videos and descriptions.",
      icon: YoutuberIcon,
    },

    {
      title: "Social media influencers",
      description:
        "Social media influencer earn by sharing their love for MIND MATCH AI with their followers.",
      icon: UserExpertIcon,
    },
  ];

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    businessWebsite: "",
    description: "",
  });

  const [formError, setFormErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
  });

  const handleInputChange = (e) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;
    const errObj = {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
    };
    if (!formData.firstName) {
      errObj.firstNameError = "Please enter first name";
      isError = true;
    } else if (!formData.lastName) {
      errObj.lastNameError = "Please enter last name";
      isError = true;
    } else if (!formData.email) {
      errObj.emailError = "Please enter company email";
      isError = true;
    }
    setFormErrors(errObj);
    if (!isError) {
      console.log("formData-->", formData);
      let affiliateData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        website: formData.businessWebsite,
        email: formData.email,
        description: formData.description
      };

      dispatch(setLoading(true));
      axios
        .post("/affiliate-program", affiliateData)
        .then((res) => {
          dispatch(setLoading(false));
          if (res.success) {
            toast.success(res.message);
            setModalShow(false);
          }
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.log(error);
        });
    }
  };

  return (
    <>
      <NavBar />
      <div className="affiliate-section">
        <h1>Join the MIND MATCH AI Affiliate Program</h1>
        <p>
        Earn 25% recurring commissions for every subscription by Helping Others Ace Their Interviews with AI-Powered Mock Interview Tools!
        </p>
        <div className="buttons">
          <button className="btn btn-primary" onClick={() => setModalShow(true)}>Apply now</button>
        </div>
      </div>

      <div className="affiliate-program container my-5 p-4">
        <h2 className="text-center">
          One of the best <span>Affiliate programs</span>
        </h2>
        <div className="row mt-4">
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="feature-card p-3">
              <h4>AI Product</h4>
              <p>One-of-a-kind AI product</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="feature-card p-3">
              <h4>Target Customers</h4>
              <p>100M+ target customers</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="feature-card p-3">
              <h4>Commissions</h4>
              <p>Upto 50% commissions</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="feature-card p-3">
              <h4>Tracking</h4>
              <p>Precision tracking & attribution</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="feature-card p-3">
              <h4>Payouts</h4>
              <p>Fast payouts & priority support</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mb-3">
            <div className="feature-card p-3">
              <h4>Offers</h4>
              <p>Exclusive offers & Creatives</p>
            </div>
          </div>
        </div>
      </div>

      <div className="affiliate-benefits p-5">
        <h2 className="text-center mb-5">Who it's for</h2>
        <div className="row">
          {cards.map((card, index) => (
            <div className="col-md-3 col-sm-6 mb-4">
              <div className="benefit-card text-center p-4">
                <div className="icon mb-3">
                  <img src={card.icon} alt={card.title} />
                </div>
                <h5>{card.title}</h5>
                <p>{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <section className="faq text-center py-5">
        <h2>Frequently Asked Questions</h2>

        <div className="row justify-content-center w-100">
          <div className="col-12 col-md-10">
            <div className="faq-item my-3">
              <h3>How do I earn commissions?</h3>
              <p>You earn 25% of the subscription fee when someone subscribe using your unique affiliate link.</p>
            </div>
            <div className="faq-item my-3">
              <h3>Are commissions recurring?</h3>
              <p>Yes! You’ll receive recurring commissions for the first 12 months of every subscription</p>
            </div>
            <div className="faq-item my-3">
              <h3>When will I get paid?</h3>
              <p>Commissions are paid out monthly. You can expect your earnings to hit your account regularly, so you can focus on what you do best—creating content.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal
        className="end-Interview-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <h3 className="text-center fw-bold">Contact Our Affiliate Program Team</h3>
            <div className="firstName-field field">
              <label htmlFor="firstName">
                First Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.firstNameError}
              </span>
            </div>
            <div className="last-name-field field">
              <label htmlFor="lastName">
                Last Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.lastNameError}
              </span>
            </div>
            <div className="company-email-field field">
              <label htmlFor="companyEmail">
                Email<span className="text-danger">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleInputChange}
              ></input>
              <span className="text-danger" style={{ fontSize: "12px" }}>
                {formError.emailError}
              </span>
            </div>
            <div className="business-name-field field">
              <label htmlFor="businessWebsite">
                Business Website(Optional)
              </label>
              <input
                type="text"
                id="businessWebsite"
                name="businessWebsite"
                onChange={handleInputChange}
              ></input>
            </div>
            <div className='description-field field'>
              <label htmlFor='description'>Description(Optional)</label>
              <textarea rows={5} id='description' name='description' onChange={handleInputChange}></textarea>
            </div>
            <div className="button-section">
              <button
                type="cancel"
                className="mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  setModalShow(false);
                }}
              >
                Cancel
              </button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Affiliate;