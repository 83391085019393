import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import { BiHide, BiShow } from 'react-icons/bi';
import { toast } from 'react-hot-toast';
import axios from '../../util/api';
import { useSearchParams } from 'react-router-dom';

export default function ResetPassword() {
    const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
    const [formErrors, setFormErrors] = useState({ passwordError: '', confirmPasswordError: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [serachParams, setSearchParams] = useSearchParams();

    const validateLoginForm = () => {
        var errors = { passwordError: '', confirmPasswordError: '' };
        let { password, confirmPassword } = formData;
        var isError = false;
        if (password.length < 4) {
            errors.passwordError = 'Enter valid password'
            isError = true;
        }

        if (password !== confirmPassword) {
            errors.confirmPasswordError = 'Password doesn\'t match'
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return ({
                ...prevData,
                [name]: value
            })
        })
    }

    function handleType() {
        setShowPassword(!showPassword)
    }

    function handleConfirmType() {
        setShowConfirmPassword(!showConfirmPassword)
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!validateLoginForm()) {
            const data = {
                password: formData.password
            }
            dispatch(setLoading(true));
            axios.post(`/reset_password/${serachParams.get('token')}`, data).then((res) => {
                dispatch(setLoading(false));
                if(res.success){
                    toast.success(res.message)
                    navigate('/login')
                }else{
                    toast.error(res.error)
                }
            }).catch((error) => {
                toast.error('Something went wrong')
                dispatch(setLoading(false));
            })
        }
    }
    return (
        <>
            <div className="login-form-container">
                <h5 className='login-heading'>Reset Password</h5>
                <form className='login-form' onSubmit={handleSubmit}>
                    <div className='password_section mt-3'>
                        <label htmlFor="password">Password</label>
                        <input type={showPassword ? 'text' : 'password'} id='password' name='password' onChange={handleChange} value={formData.password}></input>
                        {!showPassword ? (<BiHide className='hide_show' onClick={handleType} />) : (<BiShow className='hide_show' onClick={handleType} />)}
                    </div>
                    <span className='form-error'>{formErrors.passwordError}</span>
                    <div className='password_section mt-3'>
                        <label htmlFor="confirmpassword">Confirm Password</label>
                        <input type={showConfirmPassword ? 'text' : 'password'} id='confirmpassword' name='confirmPassword' onChange={handleChange} value={formData.confirmPassword}></input>
                        {!showConfirmPassword ? (<BiHide className='hide_show' onClick={handleConfirmType} />) : (<BiShow className='hide_show' onClick={handleConfirmType} />)}
                    </div>
                    <span className='form-error'>{formErrors.confirmPasswordError}</span>
                    <button className='login_btn'>Submit</button>
                </form>
            </div>
        </>
    )
}
