import React, { useEffect, useState, useRef } from "react";
import "../../scss/ApplyCoupon.scss";
import { getStripe } from "../../util/stripe";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../util/api";
import toast from "react-hot-toast";
import { setLoading } from "../../redux/userSlice";
import { v4 as uuidv4 } from "uuid";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";

function ApplyCoupon() {
  const [coupon, setCoupon] = useState("");
  const [couponsList, setCouponsList] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Coupon",
      dataIndex: "coupon",
      key: "coupon",
      width: "33%",
      ...getColumnSearchProps("coupon"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "33%",
      ...getColumnSearchProps("status"),
    },
    {
      title: "Plan Type",
      dataIndex: "planType",
      key: "planType",
      width: "33%",
      ...getColumnSearchProps("planType"),
    },
  ];

  const getUserCouponsList = () => {
    dispatch(setLoading(true));
    axios
      .get(`/get_promo_codes/${user.user_id}`)
      .then((res) => {
        dispatch(setLoading(false));
        if (res.success) {
          if (res.promo_codes && res.promo_codes.length > 0) {
            // Transform the promo_codes array: rename 'code' to 'coupon'
            const transformedPromoCodes = res.promo_codes.map(
              (promo, index) => ({
                key: index,
                coupon: promo.code,
                status: "Activated",
                planType: "Lifetime",
              })
            );
            setCouponsList(transformedPromoCodes);
          } else {
            setCouponsList([]);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else {
          toast.error("Something went wrong");
        }
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    console.log("user", user);
    if (user && user.user_id) {
      getUserCouponsList();
    }
  }, [user]);

  const createStripeUser = async () => {
    try {
      const stripe = getStripe();

      const customer = await stripe.customers.create({
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        metadata: {
          // Optional metadata (key-value pairs)
          userId: uuidv4(), // Your internal user ID for reference
          notes: "Self subscribed from coupon",
        },
      });
      if (customer && customer.id) {
        const twoYearsFromNow =
          Math.floor(Date.now() / 1000) + 730 * 24 * 60 * 60;
        stripe.subscriptions
          .create({
            customer: customer.id,
            items: [
              {
                price: "price_1QA9ZHC1l4uJrR3EXLujF7XZ",
                // price: "price_1Q8mZcC1l4uJrR3EVDJ3fKGz", // product Id added here for development
              },
            ],

            trial_end: twoYearsFromNow,
            expand: ["latest_invoice.payment_intent"],
          })
          .then((subscription) => {})
          .catch((error) => {
            console.error("Error creating subscription:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching customer:", error);
    }
  };

  const checkIsValidCoupon = async () => {
    try {
      const stripe = getStripe();
      const promotionCodes = await stripe.promotionCodes.list({
        code: coupon, // This is the promotion code the user entered
        active: true,
      });

      if (promotionCodes.data.length > 0) {
        // Promotion code is valid
        const data = {
          user_id: user.user_id,
          code: coupon,
        };
        dispatch(setLoading(true));
        axios
          .post("/add_promo_code", data)
          .then((res) => {
            dispatch(setLoading(false));
            if (res.success) {
              if (couponsList.length + 1 === 1) {
                createStripeUser();
              }
              toast.success(res.message);
              setCouponsList([
                ...couponsList,
                {
                  coupon,
                  status: "Activated",
                  key: couponsList.length + 1,
                  planType: "Lifetime",
                },
              ]);
              setCoupon("");
              setFormErrors("");
            }
          })
          .catch((error) => {
            if (error?.response?.data?.error) {
              toast.error(error?.response?.data?.error);
            } else {
              toast.error("Something went wrong");
            }
            dispatch(setLoading(false));
          });
      } else {
        // No active promotion code found
        setFormErrors("Please enter valid coupon code");
      }
    } catch (error) {
      console.error("Error fetching customer:", error);
      if (
        error &&
        error.message &&
        error.message.toLowerCase().includes("no such coupon")
      ) {
        setFormErrors("Please enter valid coupon code");
      }
    }
  };

  const handleAddCoupon = () => {
    if (coupon.trim() === "") {
      setFormErrors("Coupon code cannot be empty.");
    } else if (couponsList.length === 5) {
      toast.error("You can't add coupon more than 5");
    } else {
      checkIsValidCoupon();
    }
  };

  return (
    <div className="apply-coupon-wrapper">
      <h3 className="page-heading">Apply Coupon</h3>

      <div className="add-coupon">
        <div>
          <input
            type="text"
            id="coupon"
            name="coupon"
            placeholder="Add Coupon"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
        </div>
        <button className="apply-coupon-button" onClick={handleAddCoupon}>
          + Add
        </button>
      </div>
      {formErrors && <p className="form-errors">{formErrors}</p>}

      <div className="table-container">
        <Table
          columns={columns}
          dataSource={couponsList}
          locale={{ emptyText: "No Coupons Applied" }}
        />
      </div>
    </div>
  );
}

export default ApplyCoupon;
