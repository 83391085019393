import React, { useEffect } from 'react';
import '../../scss/TermsOfService.scss'
import Footer from '../../components/Footer';
import NavBar from '../NavBar/NavBar';

function TermsOfService() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='termsOfService-wrapper'>
        <NavBar />
        <div className='termsofservice-header'>
            <h1>Terms of Service</h1>
        </div>
          <div className="terms-content">
              <p>Please read these Terms of Service (“Terms”) carefully before using MIND MATCH
              AI LIMITED website www.mindmatchai.com and services.</p>
              <p>By accessing or using our website and services, you agree to be bound by these
              Terms. If you do not agree with any part of these Terms, please do not access or use
              our website and services.</p>
              <h2>I. Use of Our Services</h2>
              <p>You must be at least 18 years old to use our services.</p>
              <p>You agree to provide accurate and complete information when using our services.</p>
              <p>You are solely responsible for maintaining the confidentiality of your account credentials and for all
                  activities that occur under your account.</p>

              <h2>II. Intellectual Property Rights</h2>
              <p>Our website and services contain proprietary content and materials owned by us or our licensors,
                  including but not limited to text, graphics, logos, images, and software.</p>
              <p>You may not use, reproduce, modify, or distribute our proprietary content and materials without our prior
                  written consent.</p>

              <h2>III. User Content</h2>
              <p>You retain ownership of any content you submit or upload to our website and services (“User
                  Content”).</p>
              <p>By submitting User Content, you grant us a non-exclusive, worldwide, royalty-free, sublicensable, and
                  transferable license to use, reproduce, modify, adapt, publish, translate, distribute, and display such
                  User Content in connection with our services.</p>

              <h2>IV. Prohibited Activities</h2>
              <p>You agree not to engage in any of the following prohibited activities:</p>
              <ol>
                  <li>Violating any applicable laws or regulations</li>
                  <li>Impersonating any person or entity</li>
                  <li>Interfering with or disrupting our website or services</li>
                  <li>Uploading or transmitting viruses or other malicious code</li>
                  <li>Collecting or harvesting any personal information of others without their consent</li>
              </ol>

              <h2>V. Termination</h2>
              <p>We reserve the right to terminate or suspend your access to our website and services at any time and for
                  any reason without prior notice.</p>
              <p>Upon termination, your right to use our website and services will immediately cease, and any remaining
                  account balance or data associated with your account may be forfeited.</p>

              <h2>VI. Disclaimer of Warranties</h2>
              <p>Our website and services are provided on an “as is” and “as available” basis, without any warranties of
                  any kind, either express or implied.</p>
              <p>We do not warrant that our website and services will be uninterrupted, secure, or error-free, or that
                  any defects or errors will be corrected.</p>

              <h2>VII. Limitation of Liability</h2>
              <p>In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages
                  arising out of or related to your use of our website and services.</p>

              <h2>VIII. Governing Law</h2>
              <p>These Terms shall be governed by and construed in accordance with the laws of the United Kingdom.</p>

              <h2>IX. Changes to These Terms</h2>
              <p>We reserve the right to update or modify these Terms at any time without prior notice. Any changes to
                  these Terms will be effective immediately upon posting on this page.</p>
              <p>By using our website and services, you acknowledge that you have read and understood these Terms and agree
                  to be bound by them. If you do not agree to these Terms, please do not access or use our website and
                  services.</p>
          </div>
        <Footer />
    </div>
  )
}

export default TermsOfService