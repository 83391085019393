import React from 'react';
import { MdOutlineKeyboardVoice } from "react-icons/md";

function Timer(props) {
  return (
    <div className="timer-container">
        <MdOutlineKeyboardVoice />
        <div className='timer'>
            <span className="digits">
                {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
            </span>
            <span className="digits">
                {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
            </span>
        </div>
    </div>
  )
}

export default Timer