import React from 'react';
import { Doughnut } from 'react-chartjs-2';

function DoughnutChart({ percentage, color }) {
  const data1 = {
    datasets: [
      {
        label: 'Introduction',
        data: [percentage, 100 - percentage],
        backgroundColor: [
          `${color}`,
          '#0101011a'
        ],
        hoverBackgroundColor: [
          `${color}`,
          '#0101011a'
        ]
      }
    ],
  };

  const options = {
    cutout: '70%'
  }

  return (
    <div style={{width: "60px", height: "60px", position: "relative"}}>
      <Doughnut data={data1} options={options}/>
      <span style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50% , -50%)", fontSize: "14px", fontWeight: "800"}}>{`${percentage}%`}</span>
    </div>
  );
}

export default DoughnutChart;