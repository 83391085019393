import React, { useState, useEffect } from 'react'
import NavBar from '../NavBar/NavBar'
import Footer from '../../components/Footer';
import BlogCard from '../../components/BlogCard';
import blogImg1 from '../../assests/images/blogImg1.png';
import blogImg2 from '../../assests/images/blogImg2.png';
import blogImg3 from '../../assests/images/blogImg3.png';
import defaultBanner from '../../assests/images/banner-default.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import '../../scss/AdminBlogs.scss'
import axios from '../../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';

function AdminBlogs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);
    const [allBlogs, setallBlogs] = useState([]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    const handlePaginate = ({ selected }) => {
        setCurrentPage(selected + 1);
    }

    const refreshBlog = (id) => {
        setallBlogs(allBlogs.filter(item => item.id !== id));
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setLoading(true));
        axios.post('/get_all_blogs').then(res => {
            dispatch(setLoading(false));
            if (res.success) {
                setallBlogs(res.blogs);
            }
        }).catch(error => {
            dispatch(setLoading(false));
            console.log(error);
        })
    }, [])

    return (
        <div className='admin-blogs-wrapper'>
            <NavBar />
            <div className='admin-blogs-content'>
                <div className='new-blog-btn'>
                    <button onClick={() => navigate('/admin/newBlog')}>New Blog</button>
                </div>
                <div className={`cards ${location.pathname === '/admin' ? 'admin-cards': ''}`}>
                    {
                        allBlogs?.slice(indexOfFirstPost, indexOfLastPost).map((data, index) => {
                            return <BlogCard pageType={'admin'} refreshBlog={refreshBlog} key={index} imgurl={data.image} heading={data.title} description={data.description} blogId={data.id} />
                        })
                    }
                </div>
                {allBlogs.length > postsPerPage && (
                    <div className='paginate-tabs'>
                        <ReactPaginate
                            onPageChange={handlePaginate}
                            pageCount={Math.ceil(allBlogs?.length / postsPerPage)}
                            previousLabel={<GrFormPrevious />}
                            nextLabel={<GrFormNext />}
                            containerClassName={'pagination'}
                            pageLinkClassName={'page-number'}
                            previousLinkClassName={'page-number'}
                            nextLinkClassName={'page-number'}
                            activeLinkClassName={'active'}
                        />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    )
}

export default AdminBlogs