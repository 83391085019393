import React, { useState, useEffect } from 'react';
import "../../scss/Technical.scss";
import Modal from 'react-bootstrap/Modal';
import Logo from '../../assests/images/logo.png';
import headerLogo from '../../assests/images/headerLogo.png'
import Arrow from '../../assests/images/Arrow.png';
import questionAIProfile from '../../assests/images/questionAIprofile.png';
import boyAI from '../../assests/images/boyai.png';
import demoUserImg from '../../assests/images/sarah.png';
import profilePhoto from '../../assests/images/sarah.png';
import { GoClock } from "react-icons/go";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { LuSend } from "react-icons/lu";
import axios from '../../util/api';
import { TiTick } from "react-icons/ti";
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setSelectedRounds, setTextVisible } from '../../redux/userSlice';
import axiouus from 'axios';
import { backend_url } from '../../util/config';
import { IoStar } from "react-icons/io5";
import { IoStarHalf } from "react-icons/io5";
import { IoStarOutline } from "react-icons/io5";
import toast from 'react-hot-toast';
import Webcam from "react-webcam";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Timer from '../../components/Timer';
import { GrPowerReset } from "react-icons/gr";
import { HiSpeakerWave } from "react-icons/hi2";
import { PiSpeakerSimpleSlashFill } from "react-icons/pi";

function Technical() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user)
    const selectedRounds = useSelector(state => state.user.selectedRounds);
    const data = JSON.parse(sessionStorage.getItem("data"));
    const [userInput, setUserInput] = useState('');
    const [submitted, setSubmitted] = useState('');
    const [question, setQuestion] = useState('');
    const [questionCount, setQuestionCount] = useState(1);
    const [session_id, setSessionId] = useState(null);
    const [allRounds, setAllRounds] = useState(['Introduction', 'Technical', 'Managerial/ Behavioural', 'Human Resources']);
    const [tabActive, setTabActive] = useState((selectedRounds && selectedRounds[0]) ? selectedRounds[0].type : '');
    const location = useLocation();
    const [btnActive, setBtnActive] = useState("");
    const [feedback, setFeedback] = useState('');
    const [suggestedAnswer, setSuggestedAnswer] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [cameraAccess, setCameraAccess] = useState(null);
    const [speechBtn, setSpeechBtn] = useState('answer');
    const [timerRunning, setTimerRunning] = useState(false)
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isListening, setisListening] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [time, setTime] = useState(0);
    let user_Data = JSON.parse(localStorage.getItem('user'));
    const [overallFeedback, setoverallFeedback] = useState(0);
    const [utterance, setUtterance] = useState(null);
    const [playVoice, setPlayVoice] = useState(false);
    const [femaleVoice, setFemaleVoice] = useState(null);
    const [maleVoice, setmaleVoice] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const handleStart = () => {
        setTimerRunning(true);
        setIsActive(true);
        setIsPaused(false);
    };

    const stopTimer = () => {
        setTimerRunning(false);
        setIsActive(false);
        setTime(0);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1025) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let interval = null;
        if (isActive && isPaused === false) {
            interval = setInterval(() => {
                setTime((time) => time + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused])


    useEffect(() => {
        if (isListening) {
            setUserInput(transcript);
        }
    }, [transcript]);

    const videoConstraints = {
        facingMode: "user",
        height: 280
    };

    useEffect(() => {
        const checkCameraPermission = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                setCameraAccess(true);
                stream.getTracks().forEach(track => track.stop());
            } catch (error) {
                setCameraAccess(false);
            }
        };

        checkCameraPermission();
    }, []);

    const handleListen = () => {
        setSpeechBtn('stop');
        handleStart();
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            alert("Your browser doesn't support speech recognition. Please try a different browser.");
            return;
        }
        setisListening(true);
        SpeechRecognition.startListening({ continuous: true });
    };

    const handleStop = () => {
        setSpeechBtn('answer');
        setisListening(false);
        SpeechRecognition.stopListening();
        stopTimer();
    }

    const handleResetTranscript = () => {
        resetTranscript();
        setUserInput('');
    }

    const getEndPoint = (tabActive, isNext) => {
        let apiEndpoint;
        if (isNext) {
            if (tabActive === 'Introduction') {
                apiEndpoint = 'continueIntro';
            } else if (tabActive === 'Technical') {
                apiEndpoint = 'continueTechnical';
            } else if (tabActive === 'Managerial/ Behavioural') {
                apiEndpoint = 'continueManagerial';
            } else if (tabActive === 'Human Resources') {
                apiEndpoint = 'continueHR'
            }
        } else {
            if (tabActive === 'Introduction') {
                apiEndpoint = 'startIntro';
            } else if (tabActive === 'Technical') {
                apiEndpoint = 'startTechnical';
            } else if (tabActive === 'Managerial/ Behavioural') {
                apiEndpoint = 'startManagerial';
            } else if (tabActive === 'Human Resources') {
                apiEndpoint = 'startHR'
            }
        }
        return apiEndpoint;
    }

    useEffect(() => {
        // let user = JSON.parse(localStorage.getItem('user'));
        if (tabActive && user_Data) {
            let currentRound = selectedRounds.find(round => round.type === tabActive);
            if (currentRound && !currentRound.started) {
                let apiEndpoint = getEndPoint(tabActive, false);
                let payLoad = { ...data };
                payLoad.duration = currentRound.duration;
                payLoad.interview_level = currentRound.level;
                payLoad.user_id = user_Data.user_id;
                payLoad.round_name = tabActive;
                dispatch(setLoading(true));
                axios.post(`/${apiEndpoint}`, payLoad).then((res) => {
                    sessionStorage.setItem("sessionId", res.session_id);
                    setSessionId(res.session_id);
                    setQuestion(res.first_question);
                    setTotalQuestion(res.num_questions);
                    setQuestionCount(1);
                    const updatedRounds = selectedRounds.map(round => {
                        if (round.type === tabActive) {
                            return { ...round, started: true };
                        }
                        return round;
                    });

                    sessionStorage.setItem("selectedRounds", JSON.stringify(updatedRounds));
                    dispatch(setSelectedRounds(updatedRounds));
                    dispatch(setLoading(false));
                }).catch((error) => {
                    dispatch(setLoading(false));
                    console.log(error)
                })
            } else if (currentRound && currentRound.started) {
                let apiEndpoint = getEndPoint(tabActive, true);
                dispatch(setLoading(true));
                axios.post(`/${apiEndpoint}`, api_Data).then((res) => {
                    dispatch(setLoading(false));
                }).catch((error) => {
                    dispatch(setLoading(false));
                    console.log(error)
                })
            }
        }
    }, [tabActive]);

    useEffect(() => {
        if (btnActive === 'feedback') {
            let data = JSON.stringify({
                "question": question,
                "user_response": submitted
            });
            const config = {
                method: 'post',
                url: `${backend_url}/generate_feedback`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            }
            if (submitted) {
                if (!feedback) {
                    dispatch(setLoading(true));
                    axiouus.request(config).then((res) => {
                        setFeedback(res?.data?.generated_feedback?.replace(/"/g, ''));
                        dispatch(setLoading(false))
                    }).catch((error) => {
                        dispatch(setLoading(false));
                        console.log("error", error)
                    })
                }
            } else {
                toast.error('Please provide answer');
            }
        } else if (btnActive === 'suggested') {
            let data = JSON.stringify({
                "question": question,
                "user_response": submitted,
                "user_id": user_Data.user_id,
                "feedback": feedback
            });
            const config = {
                method: 'post',
                url: `${backend_url}/suggested_ans`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            }
            if (!suggestedAnswer) {
                dispatch(setLoading(true));
                axiouus.request(config).then((res) => {
                    dispatch(setLoading(false));
                    setSuggestedAnswer(res?.data?.generated_feedback);
                    setFeedback(res?.data?.feedback?.replace(/"/g, ''));
                }).catch((error) => {
                    dispatch(setLoading(false))
                    console.log("error", error)
                })
            }
        }
    }, [btnActive])

    useEffect(() => {
        const synth = window.speechSynthesis;

        const loadVoices = () => {
            const voices = synth.getVoices();
            const femaleVoices = voices.filter(voice => voice.gender === 'female' || voice.name.includes('Female') || voice.name.includes('female'));
            if (data?.interviewer_gender === 'female') {
                setFemaleVoice(femaleVoices.length > 0 ? femaleVoices[0] : null);
            } else {
                const maleVoice = voices.filter(voice => voice.name === 'Microsoft Ravi - English (India)');
                if (maleVoice.length > 0) {
                    setmaleVoice(maleVoice[0]);
                }
            }
        };

        loadVoices();

        const u = new SpeechSynthesisUtterance(question);
        setUtterance(u);
        return () => {
            synth.cancel();
        };
    }, [question, data?.interviewer_gender]);

    useEffect(() => {
        if (utterance) {
            const synth = window.speechSynthesis;

            if (femaleVoice && data?.interviewer_gender === 'female') {
                utterance.voice = femaleVoice;
                setPlayVoice(true);
                synth.speak(utterance);

                utterance.onend = () => {
                    setPlayVoice(false);
                };
            } else if (maleVoice && data?.interviewer_gender !== 'female') {
                utterance.voice = maleVoice;
                utterance.rate = 0.9;
                setPlayVoice(true);
                synth.speak(utterance);

                utterance.onend = () => {
                    setPlayVoice(false);
                };
            } else {
                setPlayVoice(true);
                synth.speak(utterance);

                utterance.onend = () => {
                    setPlayVoice(false);
                };
            }
        }
    }, [utterance, femaleVoice, maleVoice, data?.interviewer_gender]);

    const handlePlay = () => {
        setPlayVoice(true);
        const synth = window.speechSynthesis;

        if (isPaused) {
            synth.resume();
        }

        synth.speak(utterance);
        utterance.onend = () => {
            setPlayVoice(false);
        };
    };

    const handleStopSpeaker = () => {
        setPlayVoice(false)
        const synth = window.speechSynthesis;

        synth.cancel();
    };

    const api_Data = {
        session_id: session_id,
        user_response: submitted ? submitted : 'User does not provide any answer',
        user_id: user_Data.user_id
    }

    const handleUserInput = (e) => {
        setUserInput(e.target.value);
        // setSubmitted(e.target.value)
    }

    const handleNextQuestion = () => {
        handleStop();
        resetTranscript();
        setUserInput('');
        setFeedback('');
        setSuggestedAnswer('');
        setBtnActive('');
        let endPoint = getEndPoint(tabActive, true);

        if (questionCount <= totalQuestion) {
            dispatch(setLoading(true));
            axios.post(`/${endPoint}`, api_Data).then((res) => {
                dispatch(setLoading(false));
                setSubmitted('');
                setUserInput('');
                resetTranscript();

                if (questionCount === totalQuestion) {
                    let currentRound = selectedRounds.find((round) => round.type === tabActive);
                    let updatedSelectedRounds;
                    if (currentRound) {
                        updatedSelectedRounds = selectedRounds.map(round => {
                            if (round === currentRound) {
                                return { ...round, isFinished: true };
                            }
                            return round;
                        });
                        dispatch(setSelectedRounds(updatedSelectedRounds));
                    }
                    dispatch(setLoading(true));
                    dispatch(setTextVisible(true));
                    axios.post('/endInterview', { session_id }).then((res) => {
                        if (res.Finish) {
                            setoverallFeedback(res.average_score);
                            let isModelOpen = true;
                            updatedSelectedRounds.forEach(item => {
                                isModelOpen = item.isFinished;
                            })
                            if (isModelOpen === true) {
                                setModalShow(true);
                            }
                            handleNextRound();
                        }
                        dispatch(setLoading(false));
                        dispatch(setTextVisible(false));
                    }).catch(error => {
                        console.log(error);
                        toast.error('Something went wrong')
                        dispatch(setTextVisible(false));
                        dispatch(setLoading(false));
                    })
                } else {
                    setQuestionCount(questionCount + 1)
                    setQuestion(res.next_question);
                }

            }).catch((error) => {
                dispatch(setLoading(false));
                console.log("error is: ", error)
            })
        }
    }

    const handleNextRound = () => {
        handleStop();
        setTimeout(() => {
            resetTranscript();
            setUserInput('');
        }, 1000)

        selectedRounds.forEach((element, index) => {
            if (element.type === tabActive) {
                if (index < selectedRounds.length - 1) {
                    setTabActive(selectedRounds[index + 1]?.type);
                    return;
                }
                // else if (index === selectedRounds.length - 1) {
                //     handleEndInterview();
                //     return;
                // }
            }
        });
    }

    const handleEndInterview = () => {
        handleStop();
        setTimeout(() => {
            resetTranscript();
            setUserInput('');
        }, 1000);
        if (session_id) {
            dispatch(setLoading(true));
            dispatch(setTextVisible(true));
            axios.post('/endInterview', { session_id }).then((res) => {
                dispatch(setLoading(false));
                dispatch(setTextVisible(false));
                if (res.Finish) {
                    toast.success(res.message)
                    setoverallFeedback(res.average_score);
                    setModalShow(true);
                }
            }).catch(error => {
                console.log(error);
                toast.error('Something went wrong')
                dispatch(setTextVisible(false));
                dispatch(setLoading(false));
            })
        }
    }

    const handleSubmit = () => {
        setSubmitted(userInput);
        setFeedback('');
        setSuggestedAnswer('');
        setBtnActive('');
        handleStop();
    }

    const handleFeedback = () => {
        if (submitted) {
            setBtnActive("feedback")
        } else {
            toast.error('Please provide answer')
        }
    }

    const handleRetry = (e) => {
        e.preventDefault();
        let currentRound = selectedRounds.find(round => round.type === tabActive);
        if (currentRound) {
            let apiEndpoint = getEndPoint(tabActive, false);
            let payLoad = { ...data };
            payLoad.duration = currentRound.duration;
            payLoad.interview_level = currentRound.level;
            payLoad.user_id = user_Data.user_id;
            payLoad.round_name = tabActive;
            dispatch(setLoading(true));
            axios.post(`/${apiEndpoint}`, payLoad).then((res) => {
                sessionStorage.setItem("sessionId", res.session_id);
                setSessionId(res.session_id);
                setQuestion(res.first_question);
                setTotalQuestion(res.num_questions);
                setQuestionCount(1);
                const updatedRounds = selectedRounds.map(round => {
                    if (round.type === tabActive) {
                        return { ...round, started: true };
                    }
                    return round;
                });

                sessionStorage.setItem("selectedRounds", JSON.stringify(updatedRounds));
                dispatch(setSelectedRounds(updatedRounds));
                dispatch(setLoading(false));
            }).catch((error) => {
                dispatch(setLoading(false));
                console.log(error)
            })
        }
    }

    // const handleSubmitInterview = () => {
    //     if(session_id){
    //         dispatch(setLoading(true));
    //         axios.post('/submit_round', {session_id}).then((res)=>{
    //             console.log(res);
    //             if(res.Finish){
    //                 handleNextRound();
    //             }
    //             dispatch(setLoading(false));
    //         }).catch(error=>{
    //             console.log(error);
    //             dispatch(setLoading(false));
    //         })
    //     }
    // }

    return (
        session_id && (
            <div className='interview-process-wrapper'>
                <div className='left-section' style={isMobile ? { width: "100%" } : {}}>
                    <header>
                        <div className='nav-logo p-0' onClick={() => navigate('/')}>
                            <img className='logo' src={headerLogo} alt='logo'></img>
                            <span>MIND MATCH AI</span>
                        </div>
                    </header>
                    <div className='content-body'>
                        <div className='steps-container'>
                            <div className='steps'>
                                {allRounds.map((round, index) => {
                                    let isRoundSelected = false;
                                    let isRoundFinished = false;
                                    selectedRounds.forEach(element => {
                                        if (element.type === round) {
                                            isRoundSelected = true;
                                        }
                                        if (element.type === round && element.isFinished === true) {
                                            isRoundFinished = true;
                                        }
                                    });
                                    return <div className={`step tabs-${isRoundSelected ? "selected" : "non-selected"} tab-${tabActive === round ? 'active' : ''}`} key={index} onClick={() => setTabActive(round)}>
                                        <span className={`round-${isRoundFinished ? 'finished' : ''}`}>{isRoundFinished ? <TiTick size={20} /> : `0${index + 1}`}</span>
                                        <p className='m-0'>{round}</p>
                                        {index !== allRounds.length - 1 && !isMobile && (<img src={Arrow} alt='arrow'></img>)}
                                    </div>
                                })}
                            </div>
                        </div>
                        {isMobile && (
                            <div className='right-section' style={isMobile ? { width: "100%" } : {}}>
                                <header>
                                    <div>
                                        <GoClock />
                                        <span>{tabActive}</span>
                                    </div>
                                    <div>
                                        {questionCount}/{totalQuestion}
                                    </div>
                                </header>
                                <div className='content-body'>
                                    <div className='feedback-section'>
                                        <div className='buttons'>
                                            <button className={`feedback ${btnActive === "feedback" ? "active" : ""}`} onClick={handleFeedback}>Feedback</button>
                                            <button className={`suggested-answer ${btnActive === "suggested" ? "active" : ""}`} onClick={() => setBtnActive("suggested")}>Suggested Answer</button>
                                        </div>
                                    </div>
                                    <div className='description-with-profile'>
                                        <div className='description'>
                                            {btnActive === 'feedback' && (
                                                <p>{feedback}</p>
                                            )}

                                            {btnActive === 'suggested' && (
                                                <p>{suggestedAnswer}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='question-answer-container'>
                            <div className='question'>
                                <img src={data?.interviewer_gender === 'female' ? questionAIProfile : boyAI} className='profile-Img' alt='img'></img>
                                <div className='content-box'>
                                    <h6>{`Question 0${questionCount}`} {playVoice ? (<HiSpeakerWave onClick={handleStopSpeaker} />) : (<PiSpeakerSimpleSlashFill onClick={handlePlay} />)}</h6>
                                    <p>{question}</p>
                                </div>
                            </div>
                            {submitted && (
                                <div className='answer'>
                                    <img src={user?.profileUrl ? `data:image/png;base64,${user?.profileUrl}` : demoUserImg} className='profile-Img' alt='img'></img>
                                    <div className='content-box'>
                                        <h6>Answer</h6>
                                        <p>{submitted}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='user-input-container'>
                            <div className='action-buttons'>
                                <div>
                                    <button className='end-interview' onClick={handleEndInterview}>End Interview</button>
                                    <button className='next-round' onClick={handleNextRound}>Next Round</button>
                                    <button className='re-try' onClick={handleRetry}>Re-Try</button>
                                </div>
                                <div>
                                    {speechBtn === 'answer' ? (
                                        <button className='answer' onClick={handleListen}>Answer<MdOutlineKeyboardVoice /></button>
                                    ) : (
                                        <button className='answer stop' onClick={handleStop}>Stop</button>
                                    )}
                                    <button className='submit' onClick={handleSubmit}>Submit<LuSend /></button>
                                    <button className='next-question' onClick={handleNextQuestion}>Next Question</button>
                                </div>


                            </div>
                            <div className='input-box-container'>
                                <div className='input-box'>
                                    <textarea rows={4} placeholder='Type your answer...' onChange={handleUserInput} value={userInput}></textarea>
                                    {timerRunning && (
                                        <Timer className="timer-wrapper" time={time} />
                                    )}
                                    <GrPowerReset className='reset-btn' onClick={handleResetTranscript} />
                                </div>
                                {isMobile && (
                                    cameraAccess ? (
                                        <div className='camera-screen-section'>
                                            <Webcam className='camera-screen' videoConstraints={videoConstraints} />
                                        </div>
                                    ) : (
                                        <div className='profile'>
                                            <img src={user?.profileUrl ? `data:image/png;base64,${user?.profileUrl}` : profilePhoto} alt='img'></img>
                                            <span className='user-name'>{user?.first_name} {user?.last_name}</span>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!isMobile && (
                    <div className='right-section'>
                        <header>
                            <div>
                                <GoClock />
                                <span>{tabActive}</span>
                            </div>
                            <div>
                                {questionCount}/{totalQuestion}
                            </div>
                        </header>
                        <div className='content-body'>
                            <div className='feedback-section'>
                                <div className='buttons'>
                                    <button className={`feedback ${btnActive === "feedback" ? "active" : ""}`} onClick={handleFeedback}>Feedback</button>
                                    <button className={`suggested-answer ${btnActive === "suggested" ? "active" : ""}`} onClick={() => setBtnActive("suggested")}>Suggested Answer</button>
                                </div>
                            </div>
                            <div className='description-with-profile'>
                                <div className='description'>
                                    {btnActive === 'feedback' && (
                                        <p>{feedback}</p>
                                    )}

                                    {btnActive === 'suggested' && (
                                        <p>{suggestedAnswer}</p>
                                    )}
                                </div>
                                {cameraAccess ? (
                                    <div className='camera-screen-section'>
                                        <div className='camera-note-section'>
                                            <p>We don't record and save the video</p>
                                        </div>
                                        <Webcam className='camera-screen' videoConstraints={videoConstraints} />
                                    </div>
                                ) : (
                                    <div className='profile'>
                                        <img src={user?.profileUrl ? `data:image/png;base64,${user?.profileUrl}` : profilePhoto} alt='img'></img>
                                        <span className='user-name'>{user?.first_name} {user?.last_name}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <Modal
                    className='end-Interview-modal'
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modalShow}
                    onHide={() => { setModalShow(false); navigate('/feedback', { replace: true }); }}
                >
                    <Modal.Body>
                        <div className='score-rating'>
                            <div className='score'>{overallFeedback ? overallFeedback : 0}<span>/5</span></div>
                            <div className='rating'>
                                <IoStar />
                                <IoStar />
                                <IoStar />
                                <IoStarHalf />
                                <IoStarOutline />
                            </div>
                        </div>
                        <p>
                            Good Job! Click below to see the detailed report
                        </p>
                        <button className='btn' onClick={() => navigate('/feedback', { replace: true })}>See Detailed Report</button>
                    </Modal.Body>
                </Modal>
            </div>
        ))
}

export default Technical