import React, { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import { toast } from 'react-hot-toast';
import axios from '../../util/api';

export default function Signup() {
    const [formData, setFormData] = useState({ firstname: '', lastname: '', email: '', Password: '' });
    const [formErrors, setFormErrors] = useState({ emailError: '', passwordError: '' });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateLoginForm = () => {
        var errors = { emailError: '', passwordError: '', firstNameError: '', lastNameError: '' };
        let { email, Password, firstname, lastname } = formData;
        var isError = false;

        // if (firstname.length < 3) {
        //     errors.firstNameError = 'Enter Valid First Name'
        //     isError = true;
        // }

        // if (lastname.length < 3) {
        //     errors.lastNameError = 'Enter Valid Last Name'
        //     isError = true;
        // }

        if (!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            errors.emailError = 'Enter Valid email'
            isError = true;
        }

        if (Password.length < 4) {
            errors.passwordError = 'Enter valid password'
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return ({
                ...prevData,
                [name]: value
            })
        })
    }

    function handleType() {
        setShowPassword(!showPassword)
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!validateLoginForm()) {
            const data = {
                username: `${formData.firstname} ${formData.lastname}`,
                email: formData.email,
                password: formData.Password
            }
            dispatch(setLoading(true));
            axios.post('/register', data).then((res) => {
                dispatch(setLoading(false));
                if (res.success) {
                    toast.success(res.message);
                    navigate('/login');
                }
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    toast.error(error?.response?.data?.error)
                }else{
                    toast.error('Something went wrong')
                }
                dispatch(setLoading(false));
            })
        }
    }
    return (
        <>
            <div className="signup-form-container">
                <h5 className='signup-heading'>Sign Up with your credentials</h5>
                <form className='signup-form' onSubmit={handleSubmit}>
                    <div className='name_section'>
                        <div className='firstname_section'>
                            <label htmlFor="firstname">First Name</label>
                            <input type='text' id='firstname' name='firstname' onChange={handleChange} value={formData.firstname} autoComplete='off' required></input>
                            <span className='form-error'>{formErrors.firstNameError}</span>
                        </div>
                        <div className='lastname_section'>
                            <label htmlFor="lastname">Last Name</label>
                            <input type='text' id='lastname' name='lastname' onChange={handleChange} value={formData.lastname} autoComplete='off'></input>
                            <span className='form-error'>{formErrors.lastNameError}</span>
                        </div>
                    </div>
                    <div className='email_section mt-3'>
                        <label htmlFor="email">Email</label>
                        <input type='email' id='email' name='email' onChange={handleChange} value={formData.email} autoComplete='off'></input>
                    </div>
                    <span className='form-error'>{formErrors.emailError}</span>
                    <div className='password_section mt-3'>
                        <label htmlFor="password">Password</label>
                        <input type={showPassword ? 'text' : 'password'} id='password' name='Password' onChange={handleChange} value={formData.Password}></input>
                        {!showPassword ? (<BiHide className='hide_show' onClick={handleType} />) : (<BiShow className='hide_show' onClick={handleType} />)}
                    </div>
                    <span className='form-error'>{formErrors.passwordError}</span>
                    <button className='signup_btn'>Sign Up</button>
                </form>
            </div>
        </>
    )
}
