import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TiTick } from "react-icons/ti";
import '../../scss/PaymentSuccess.scss'
import Stripe from 'stripe';
import { getStripe } from '../../util/stripe';

function Success() {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const paymentSuccess = urlParams.get('success');
    const sessionId = urlParams.get('session_id');

    const checkPaymentDetails = async () => {
        const stripe1 = getStripe();
        try {
            const session = await stripe1.checkout.sessions.retrieve(sessionId);
            if (!session) {
                navigate('/');
            } else if (session.status === 'complete' || session.payment_status === 'paid') {

            }
        } catch (error) {
            navigate('/');
        }
    }

    useEffect(() => {
        if (sessionId) {
            checkPaymentDetails();
        } else {
            navigate('/');
        }
    }, [])


    return (
        <>
            {paymentSuccess && (
                <div className='success-wrapper'>
                    <div className='success-container'>
                        <div className='success-logo'>
                            <TiTick />
                        </div>
                        <h1>Payment Successfull</h1>
                        <Link to='/'>Click here to go back to Home Page</Link>
                    </div>
                </div>
            )}
        </>
    )
}

export default Success