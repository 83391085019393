import React from 'react';
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import footerLogo from '../assests/images/footerLogo.png';
import faceBookLogo from '../assests/images/facebookLogo.png';
import instagramLogo from '../assests/images/InstagramLogo.png';
import linkedLogo from '../assests/images/linkedInLogo.png';
import XLogo from '../assests/images/XLogo.png';
import "../scss/Footer.scss";

function Footer(props) {
    const navigate = useNavigate();
    return (
        <div className='footer-wrapper'>
            <div className='footer-body-section'>
                <div className='heading-section'>
                    <h2>Ready for an Interview?</h2>
                    <button className='btn try-free-btn' onClick={() => props.handleSubscription()}>Try for free</button>
                </div>
                <div className='footer-bottom-section'>
                    <div className='nav-section'>
                        <img src={footerLogo} alt='logo'></img>
                        <div className='nav-links'>
                            <Link to="/" state={{ ClickedOn: "aboutUs" }}>About Us</Link>
                            <Link to="/" state={{ ClickedOn: "pricing" }}>Pricing</Link>
                            <Link to="/blogs">Blogs</Link>
                            <Link to="/affiliate">Affiliate Program</Link>
                        </div>
                        <div className='socials'>
                            <Link to='https://www.facebook.com/profile.php?id=61558626302399' target="_blank" rel="noopener noreferrer">
                                <img src={faceBookLogo} className='logo' alt='logo'></img>
                            </Link>
                            <Link to='https://www.instagram.com/mind_match_ai' target="_blank" rel="noopener noreferrer">
                                <img src={instagramLogo} className='logo' alt='logo'></img>
                            </Link>
                            <Link to='https://x.com/mindmatchai' target="_blank" rel="noopener noreferrer">
                                <img src={XLogo} className='logo' alt='logo'></img>
                            </Link>
                            <Link to='https://www.linkedin.com/company/mindmatchai' target="_blank" rel="noopener noreferrer">
                                <img src={linkedLogo} className='logo' alt='logo'></img>
                            </Link>
                        </div>
                    </div>
                    <div className='copyright-section'>
                        <p>&copy; Copyright 2024<span>MIND MATCH AI LIMITED.</span>All Rights Reserved</p>
                        <div className='nav-links'>
                            <Link to='' onClick={()=> props.setModalShow(true)}>Contact Us</Link>
                            <Link to="/privacyPolicy">Privacy Policy</Link>
                            <Link to="/termsOfService">Terms of Service</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer