import React, { useEffect, useState } from 'react'
import Styled from 'styled-components';
import google from '../assests/images/Google.png';
import { useGoogleLogin } from '@react-oauth/google';
import axios from '../util/api';
import { useNavigate } from 'react-router';
import FacebookLogin from 'react-facebook-login';
import { toast } from 'react-hot-toast';
import { IoLogoFacebook } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';
import '../styles/_mixins.scss';
import Cookies from 'js-cookie';

export default function SocialLogin({ page }) {
    const dispatch = useDispatch();
    const login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });
    const navigate = useNavigate();

    const getUniqueId = () => {
        let id = localStorage.getItem('uniqueId');
        if (!id) {
            id = Cookies.get('uniqueId');
            if (!id) {
                return '';
            }
        }
        return id;
    };

    const responseFacebook = async (response) => {
        const { email, name } = response;
        try {
            if (email && name) {
                axios.post('/store_user_data', { username: name, email, password: '', device_id: getUniqueId() }).then((res) => {
                    if (res.success) {
                        const nameArray = name?.split(' ');
                        let user = {
                            first_name: nameArray[0] || '',
                            last_name: nameArray[1] || '',
                            email: email,
                            user_id: res.user_id
                        }
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(setUser(user));
                        if (res.has_profile) {
                            navigate('/interview');
                        } else {
                            navigate('/profile');
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    toast.error('Internal server error')
                })
            }
        } catch (error) {
            console.log(error);
            toast.error('Some Error Occured')
        }
    }

    async function handleGoogleLoginSuccess(tokenResponse) {
        const accessToken = tokenResponse.access_token;
        try {
            const { name, email } = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            if (email && name) {
                axios.post('/store_user_data', { username: name, email, password: '', device_id: getUniqueId() }).then((res) => {
                    if (res.success) {
                        const nameArray = name?.split(' ');
                        let user = {
                            first_name: nameArray[0] || '',
                            last_name: nameArray[1] || '',
                            email: email,
                            user_id: res.user_id
                        }
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(setUser(user));
                        let isSubscribe = sessionStorage.getItem('isSubscribe');
                        if (isSubscribe === 'true' || isSubscribe === true) {
                            sessionStorage.removeItem('isSubscribe');
                            navigate('/subscription')
                        } else if (res.has_profile) {
                            navigate('/interview');
                        } else {
                            navigate('/profile');
                        }
                    }
                }).catch(error => {
                    console.log(error);
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Wrapper>
            <div className="social-container">
                <div className="google-login" onClick={login}><img src={google} alt="logo" />Sign {page === 'login' ? 'in' : 'up'} with Google</div>
                <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fields='name, email'
                    callback={responseFacebook}
                    textButton={page === 'login' ? 'Sign in with Facebook' : 'Sign up with Facebook'}
                    cssClass='custom-facebook-btn'
                    icon={<IoLogoFacebook />}
                />
            </div>
        </Wrapper>
    )
}

const Wrapper = Styled.div`
.social-container{
    display: flex;
    gap: 20px;
    margin-top: 32px;
    justify-content: space-around;

    @media only screen and (max-width: 600px){
        flex-direction: column;
    }

    .google-login {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 6px;
        border-radius: 8px;
        border: 1px solid #104CBA;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        width: 50%;
        cursor: pointer;

        @media only screen and (max-width: 600px){
            width: 100%;
        }

        button {
            background-color: transparent;
            color: black;
            border: none;
            text-transform: lowercase;
            padding: 0;
        }


        img{
            width: 24px;
            height: 24px;
        }
    }

    .custom-facebook-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 6px;
        border-radius: 8px;
        border: 1px solid #104CBA;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
        width: 100%;
        background: transparent;

        svg{
            width: 35px;
            height: 35px;
            color: #1877F2;
        }
    }

    >span{
        width: 50%;

        @media only screen and (max-width: 600px){
            width: 100%;
        }
    }
}
`;
